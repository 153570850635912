import React from "react";
import { Col, Container, Row } from "reactstrap";

//import Components
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Lead from "./Lead";
import lead from "../../assets/scss/pages/lead.css";
import { AlignCenter } from "feather-icons-react/build/IconComponents";

const DashboardAnalytics = () => {
  document.title = "Pradhan Portal App";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard"   />
          <Row>
            <Col lg={12}>
              <h2 style={{ "text-align": "center", "margin-top": "100px" }}>
                The dashboard is currently under construction. Please check back
                later..
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardAnalytics;
