import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
// import "../../assets/scss/pages/lead.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../../Components/Common/TableContainer';
import { time } from 'echarts';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { Pagination } from 'react-bootstrap';

import moment from 'moment';
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap" ;
import { useFormik } from "formik";

const initialValues= {     
    partnershipName:"",
}

export default function BankMgmt() {
  var renderTooltip = <span>Add New Bank</span>;

  const navigateToSettings = () => {
    navigate(`/applicationSetting`);
  };
  const navigateToAddStage=()=>{
    navigate(`/addBank`)
  }

  const [isSubmitted, setSubmitted]=useState(false);
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/addPartnership',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
    setSubmitted(true);
    toast.success('Loan Stage created successfully !', {
      position: toast.POSITION.TOP_RIGHT
  });
    },
   });

    const navigate = useNavigate();
  
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
    const toggle = () => setModal(!modal);
    const [modal, setModal] = React.useState(false); 
    const [loanStages,setLoanStages]=useState([]);
    const [selected, setSelected] = useState([]);
    const [autofetch, setAutofetch] = useState([]);
const [formData,setFormData]= useState({
  loanStage:"",raisedFor:""
})
const[dataAll,setDataAll]=useState([]);
   
    
     
    const loadLead = async (value) => {
      if (value != null) {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBank`)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      } else {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBank`)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      }
    };
    
    useEffect(() => {
      loadLead();
    }, [search]);
    const [showRecord, setshowRecord] = useState(10);
    
    const  handleChanegeShowRec = (e) => {
      const { name, value, type, checked } = e.currentTarget;
    
      setshowRecord(e.currentTarget.value);
    
      loadLead(value);
     }
    //for table records per page
    const [recordCount, setrecordCount] = useState(10);
    
    
    //for pagination
    
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    // handle next
    const handleNext = () => {
      if (page === pageCount) return page;
      setPage(page + 1)
    }
    
    // handle previous
    const handlePrevios = () => {
      if (page === 1) return page;
      setPage(page - 1)
    }
    
    useEffect(() => {
    loadLead()
    }, [page])
    
    useEffect(() => {
      const pagedatacount = Math.ceil(dataAll.length / showRecord);
      setPageCount(pagedatacount);
    
      if (page) {
          const LIMIT = showRecord;
          const skip = LIMIT * page // 5 *2 = 10
           const dataskip = dataAll.slice(page === 1 ? 0 : skip - LIMIT, skip);
          setPageData(dataskip)
      }
    }, [search,dataAll])
    useEffect(() => {
      const result=data.filter(bank=>{
        return bank.bankName?.toLowerCase().match(search?.toLowerCase())
      });
      setDataAll(result);
      // setSearch(result);
    }, [search]);
    
    const createdDate=(dataAll)=>{
      return moment(dataAll.createdDate)
      .format("DD-MMM-YYYY")
    }


  const columns = useMemo(
    () => [
      
      {
        Header: "Bank ID",
        accessor: "bankId",
      },
      {
        Header: "Bank Name",
        accessor: "bankName",
       
      },
      
    ],
    []
  );
  return (
    <fragment>
      <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
                    {/* <span className='float-start'><img src={leftChevron} width = "30" height = "25" alt='' className='mx-4'  onClick={navigateToSettings}/></span> */}
              <div className='d-inline-block'>
                <h3> Banks</h3>
              </div>
              <div className='d-inline-block'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToAddStage} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
            </div>
              </Col>
              <Col sm={5}></Col>
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                 dataAll?.length > 0 ?(
                    <>
                    <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
          <th scope="col" className="tableroww">Sr.No.</th>
       <th scope="col" className="tableroww">Bank ID</th>
         <th scope="col" className="tableroww">Bank Name</th>
       </tr>
     </thead>
     <tbody>
      
      {
          pageData?.map((dataAll,index)=>( 
              <tr key={index}>
              <th scope='row' className="tableroww1">{index + 1}</th>
              <td className="tableroww1 capitalizetxxxt">
              <Link id="leadid1" to={`/viewBank/${dataAll.bankId}`} >
        {dataAll.generatedId}
           </Link>
              </td>
              <td className="tableroww1 capitalizetxxxt">{dataAll.bankName}</td>
              </tr>
  
  
           ))
      } 
    </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            .fill(null)
                            .map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                     </>
                   ):<>
                   <div>No Records Available</div></>
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </fragment>
  )
}

