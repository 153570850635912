import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardAnalytics from "../pages/DashboardAnalytics";
import CrmLeads from "../pages/Crm/CrmLeads/index";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//APi Key
import APIKey from "../pages/APIKey/index";

//login
import Login from "../pages/Authentication/Login";

import LeadCreation from "../pages/LeadCreation";
import AddnewLead from "../pages/AddnewLead";
import UpdateLead from "../pages/updateLead";
import ViewLead from "../pages/ViewLead";
import AddNewCustomer from "../pages/CustomerManagement/CreateCustomer"
// import Customers from "../pages/CustomerManagement/Customers";
// import ViewCustomer from "../pages/CustomerManagement/ViewCustomer";
import EditCustomer from "../pages/CustomerManagement/EditCustomer";
import ApplicationSetting from "../pages/setting/applicationSetting";
import LoanStage from "../pages/setting/loanStage";
import LoanTask from "../pages/setting/loanTask";
import InsuranceTask from "../pages/setting/insuranceTask";
import LeadOwnerAssingModal from "../pages/Modals/LeadOwnerAssingModal";
import LoanCases from "../pages/loanCases/loancase";
import ViewLoanCase from "../pages/loanCases/viewLoanCase";
import TaskDetails from "../pages/loanCases/taskDetails";
import AddApplicantDetails from "../pages/loanCases/addApplicantDetails";
import AddContactDetail from "../pages/loanCases/addContactDetails";
import AddKYCDetail from "../pages/loanCases/addKYCDetails";
import AddAddressDetail from "../pages/loanCases/addAddressDetails";
import AddDocument from "../pages/loanCases/addDocuments";
import AddTask from "../pages/loanCases/addTask";
import AddSubTasks from "../pages/loanCases/subTaskDetails";
import TaskTemplate from "../pages/setting/taskTemplate";
import AddTaskTemplate from "../pages/setting/addTemplates.js";
import Branch from "../pages/setting/branchMaster/index.js";
import AddBranch from "../pages/setting/branchMaster/addBranch/index.js";
import AddLoanStages from "../pages/setting/loanStage/AddLoanStage/index.js";
import AddLoanTask from "../pages/setting/loanTask/addLoanTasks/index.js";
import EditCaseDetail from "../pages/loanCases/editCaseDetails/index.js";
import EditContactDetail from "../pages/loanCases/EditContactDetails/index.js";
import EditPermanentAddresses from "../pages/loanCases/editAddress/editPermanentAddress/index.js";
import EditCurrentAddresses from "../pages/loanCases/editAddress/editCurrentAddress/index.js";
import EditSiteAddresses from "../pages/loanCases/editAddress/editSiteAddress/index.js";
import EditOfficeAddresses from "../pages/loanCases/editAddress/editOfficeAddress/index.js";

import DeletedLeads from "../pages/DeletedLead/index.js";
import ViewDeletedLead from "../pages/viewDeletedLeads/index.js";
import ArchivedLead from "../pages/ArchivedLead/ArchivedLeadsData/index.js";
import ViewArchivdLeads from "../pages/ArchivedLead/viewArchivedLead/index.js";
import UserManagement from "../pages/setting/userManagement/userManagementPool/index.js";
import AddUser from "../pages/setting/userManagement/addUsers/index.js";
import Logout from "../pages/Authentication/Logout.js";
import ForgotPassWord from "../pages/Authentication/ForgotPassWord.js";
import VerificationCode from "../pages/Authentication/VerificationCode.js";
import ResetPassword from "../pages/Authentication/ResetPassword.js";
import UpdateUser from "../pages/setting/userManagement/editUsers/index.js";
import ViewUsers from "../pages/setting/userManagement/viewUsers/index.js";
import AddLoanTasks from "../pages/setting/addLoanTask/index.js";
import AddTemplateTasks from "../pages/setting/addLoanTask/index.js";
import ViewTemplate from "../pages/setting/viewTemplate/index.js";
import EditApplicantDetail from "../pages/loanCases/editApplicantDetails/index.js";
import AddBelowTask from "../pages/loanCases/addTaskBelow/index.js";
import CreateTaskAboveOfSequenceNo from "../pages/loanCases/addTaskAbove/index.js";
import AddTempSubTask from "../pages/setting/addLoanSubTask/index.js";
import SubTaskManagement from "../pages/setting/loanSubTask/subtask/index.js";
import AddLoanSubTask from "../pages/setting/loanSubTask/addSubTask/index.js";
import ViewCustomer from "../pages/CustomerManagement/ViewCustomer/index.js";
import Customers from "../pages/CustomerManagement/Customersmgmt/index.js";
import DocumentStorage from "../pages/setting/documentStorage/index.js";
import UserRolePrivileges from "../pages/setting/userManagement/rolePrivileges/index.js";
import LoanEligibilityCalculator from "../pages/loanEligibility/index.js";
import AddPartner from "../pages/setting/addPartner/index.js";
import AddPartnership from "../pages/setting/addPartnership/index.js";
import AddProduct from "../pages/setting/addProduct/index.js";
import PartnerManagement from "../pages/setting/addPartner/PartnerManagement/index.js";
import Partnershipmgmt from "../pages/setting/addPartnership/partnershipmgmt/Partnershipmgmt.js";
import AddPartnershipManagement from "../pages/setting/addPartnership/partnershipmgmt/index.js";
import ProductManagement from "../pages/setting/addProduct/productmgmt/index.js";
import BankManagement from "../pages/setting/bankMaster/bankManagement/index.js";
import AddNewBank from "../pages/setting/bankMaster/addBank/index.js";
import BankBranchMgmt from "../pages/setting/bankBranchMaster/bankBracnchMgmt/index.js";
import AddBnkBranch from "../pages/setting/bankBranchMaster/addBankBranch/index.js";
import TypeMgmt from "../pages/setting/typeMaster/typeMgmt/index.js";
import AddTypes from "../pages/setting/typeMaster/addType/index.js";
import LoanTaskAdd from "../pages/setting/loanTask/addLoanTasks/index.js";
import ChannelPartnerMgmt from "../pages/channelPartner/index.js";
import ViewCP from "../pages/channelPartner/viewChannelPartner/index.js";
import ViewChannelPartnership from "../pages/setting/addPartnership/partnershipmgmt/viewChannelPartner/index.js";
import EditChannelPartner from "../pages/setting/addPartnership/partnershipmgmt/editChannelPartner/index.js";
import ViewPartnerDetails from "../pages/setting/addPartner/viewPartner/index.js";
import UpdatePartner from "../pages/setting/addPartner/editPartner/index.js";
import EditBankName from "../pages/setting/bankMaster/editBank/index.js";
import ViewBnkBranch from "../pages/setting/bankBranchMaster/viewBankBranch/index.js";
import UpdateBankBranch from "../pages/setting/bankBranchMaster/editBankBranch.js/index.js";
import SubTypeMgmt from "../pages/setting/subTypeMaster/subTypeManagement/index.js";
import ViewLoanSubType from "../pages/setting/subTypeMaster/viewSubType/index.js";
import UpdateSubType from "../pages/setting/subTypeMaster/editSubType/index.js";
import ViewLoanType from "../pages/setting/typeMaster/viewType/index.js";
import UpdateType from "../pages/setting/typeMaster/editType/index.js";
import Viewbank from "../pages/setting/bankMaster/viewBank/index.js";
import ViewLoanStage from "../pages/setting/loanStage/viewLoanStage/index.js";
import UpdateLoanStage from "../pages/setting/loanStage/editLoanStage/index.js";
import ViewLoanTask from "../pages/setting/loanTask/viewLoanTask/index.js";
import UpdateLoanTask from "../pages/setting/loanTask/editLoanTask/index.js";
import ViewSubTask from "../pages/setting/loanSubTask/viewSubTask/index.js";
import UpdateLoanSubTask from "../pages/setting/loanSubTask/editSubTask/index.js";
import AddLoanSubType from "../pages/setting/subTypeMaster/addSubType/index.js";
import BankBranchMasterMgmt from "../pages/bankBranchMaster/bankBranch/index.js";
import ViewBankBranchMaster from "../pages/bankBranchMaster/viewBankBranch/index.js";



const executiveRoutes = [
  { path: "/dashboard", component: <DashboardAnalytics /> },
  {path:"/AddNewCustomer",component:<AddNewCustomer/>},
{path:"/Customers",component:<Customers/>},
{path:"/ViewCustomer/:customerId",component:<ViewCustomer/>},
{path:"/EditCustomer/:customerId",component:<EditCustomer/>},
{path:"applicationSetting",component:<ApplicationSetting/>},
{path:"/loanStage",component:<LoanStage/>},
{path:"/loanTask",component:<LoanTask/>},
{path:"/insuranceTask",component:<InsuranceTask/>},
{path:"/insuranceStage",component:<LoanTask/>},
{path:"/loanCases",component:<LoanCases/>},
{path:"/viewLoanCase/:LoanCaseId",component:<ViewLoanCase/>},
{path:"/addApplicantDetails/:LoanCaseId",component:<AddApplicantDetails/>},
{path:"/addContactDetails/:LoanCaseId",component:<AddContactDetail/>},
{path:"/addKycDetail/:LoanCaseId",component:<AddKYCDetail/>},
{path:"/addAddressDetail/:LoanCaseId",component:<AddAddressDetail/>},
{path:"/addDocuments/:LoanCaseId",component:<AddDocument/>},
{path:"/viewTaskDetails/:taskId",component:<TaskDetails/>},
{path:"/loanSubTask",component:<SubTaskManagement/>},
{path:"/addSubTask/:taskId",component:<AddSubTasks/>},
{path:"/addTask/:LoanCaseId",component:<AddTask/>},
{path:"/addTask",component:<AddTask/>},
{path:"/taskTemplates",component:<TaskTemplate/>},
{path:"/addTaskTemplates",component:<AddTaskTemplate/>},
{path:"/branchMaster",component:<Branch/>},
{path:"/bankBranchMaster",component:<BankBranchMasterMgmt/>},
{path:"/addBranch",component:<AddBranch/>},
{path:"/addLoanStage",component:<AddLoanStages/>},
{path:"/addLoanTasks",component:<LoanTaskAdd/>},
{path:"/EditApplicantDetails/:LoanCaseId",component:<EditApplicantDetail/>},
{path:"/EditCaseDetails/:LoanCaseId",component:<EditCaseDetail/>},
{path:"/EditContactDetails/:LoanCaseId",component:<EditContactDetail/>},
{path:"/EditPermanentAddress/:customerId",component:<EditPermanentAddresses/>},
{path:"/EditCurrentAddress/:customerId",component:<EditCurrentAddresses/>},
{path:"/EditSiteAddress/:LoanCaseId",component:<EditSiteAddresses/>},
{path:"/EditOfficeAddress/:customerId",component:<EditOfficeAddresses/>},
{path:"/deletedLeads",component:<DeletedLeads/>},
{path:"/ViewDeletedLead/:LeadID",component:<ViewDeletedLead/>},
{path:"/archivedLeads",component:<ArchivedLead/>},
{path:"/ViewArchivedLead/:LoanCaseId",component:<ViewArchivdLeads/>},
{path:"/userMgmtPool",component:<UserManagement/>},
{path:"/addNewUser",component:<AddUser/>},
{path:"/addRolePrivileges",component:<UserRolePrivileges/>},
{path:"/emiCalculator",component:<LoanEligibilityCalculator/>},
{path:"/editUser/:userId",component:<UpdateUser/>},
{path:"/viewUser/:userId",component:<ViewUsers/>},
{ path: "/apps-crm-leads", component: <CrmLeads /> },
{ path: "/addLoanTasks", component: <AddTemplateTasks/> },
{ path: "/viewTemplate/:templateId", component: <ViewTemplate/> },
{ path: "/addPartner", component: <AddPartner/> },
{ path: "/addPartnership", component: <AddPartnership/> },
{ path: "/addProduct", component: <AddProduct/> },
{ path: "/addPartnerManagement", component: <PartnerManagement/> },
{ path: "/addPartnershipManagement", component: <AddPartnershipManagement/> },
{ path: "/channelPartner", component: <ChannelPartnerMgmt/>},
{path:"/viewCP/:partnershipId",component:<ViewCP/>},
{path:"/viewPartner/:partnerId",component:<ViewPartnerDetails/>},
{path:"/viewLoanStage/:loanStageID",component:<ViewLoanStage/>},
{path:"/viewLoanTask/:loanTaskId",component:<ViewLoanTask/>},
{path:"/viewLoanSubTask/:subTaskId",component:<ViewSubTask/>},
{path:"/editLoanStage/:loanStageID",component:<UpdateLoanStage/>},
{path:"/editLoanTask/:loanTaskId",component:<UpdateLoanTask/>},
{path:"/editLoanSubTask/:subTaskId",component:<UpdateLoanSubTask/>},
{path:"/editPartner/:partnerId",component:<UpdatePartner/>},
{path:"/editBank/:bankId",component:<EditBankName/>},
{path:"/viewBank/:bankId",component:<Viewbank/>},
{path:"/editBankBranch/:bankBranchId",component:<UpdateBankBranch/>},
{path:"/viewBankBranch/:bankBranchId",component:<ViewBnkBranch/>},
{path:"/viewBankBranchMaster/:bankBranchId",component:<ViewBankBranchMaster/>},
{path:"/EditChannelPartner/:partnershipId",component:<EditChannelPartner/>},
{path:"/viewChannelpartner/:partnershipId",component:<ViewChannelPartnership/>},
{ path: "/addProductManagement", component: <ProductManagement/> },
{ path: "/bankmgmt", component: <BankManagement/>},
{ path: "/addBank", component: <AddNewBank/>},
{ path: "/bankBranchmgmt", component: <BankBranchMgmt/>},
{ path: "/addbankbranch", component: <AddBnkBranch/>},
{ path: "/typemgmt", component: <TypeMgmt/>},
{ path: "/addTypes", component: <AddTypes/>},
{ path: "/addSubTypes", component: <AddLoanSubType/>},
{ path: "/subTypesManagement", component: <SubTypeMgmt/>},
{ path: "/viewSubType/:subTypeID", component: <ViewLoanSubType/>},
{ path: "/editSubType/:subTypeID", component: <UpdateSubType/>},
{ path: "/viewType/:typeId", component: <ViewLoanType/>},
{ path: "/editType/:typeId", component: <UpdateType/>},
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
]
const authProtectedRoutes = [

  {path:"/LeadCreation-Lead", component:<LeadCreation/>},
  {path:"/ViewLead/:LeadID",component:<ViewLead/>},
  {path:"/addLead",component:<AddnewLead/>},
  {path:"/UpdateLead/:LeadID",component:<UpdateLead/>},
  { path: "/dashboard", component: <DashboardAnalytics /> },
  {path:"/assignLead/:LeadID",component:<LeadOwnerAssingModal/>},
{path:"/AddNewCustomer",component:<AddNewCustomer/>},
{path:"/Customers",component:<Customers/>},
{path:"/ViewCustomer/:customerId",component:<ViewCustomer/>},
{path:"/EditCustomer/:customerId",component:<EditCustomer/>},
{path:"applicationSetting",component:<ApplicationSetting/>},
{path:"/loanStage",component:<LoanStage/>},
{path:"/loanTask",component:<LoanTask/>},
{path:"/documentStorage",component:<DocumentStorage/>},
{path:"/loanSubTask",component:<SubTaskManagement/>},
{path:"/addLoanSubTask",component:<AddLoanSubTask/>},
{path:"/insuranceTask",component:<InsuranceTask/>},
{path:"/insuranceStage",component:<LoanTask/>},
{path:"/loanCases",component:<LoanCases/>},
{path:"/viewLoanCase/:LoanCaseId",component:<ViewLoanCase/>},
{path:"/addApplicantDetails/:LoanCaseId",component:<AddApplicantDetails/>},
{path:"/addContactDetails/:LoanCaseId",component:<AddContactDetail/>},
{path:"/addKycDetail/:LoanCaseId",component:<AddKYCDetail/>},
{path:"/addAddressDetail/:LoanCaseId",component:<AddAddressDetail/>},
{path:"/addDocuments/:LoanCaseId",component:<AddDocument/>},
{path:"/viewTaskDetails/:taskId",component:<TaskDetails/>},
{path:"/addSubTask/:taskId",component:<AddSubTasks/>},
{path:"/addTask/:LoanCaseId",component:<AddTask/>},
{path:"/addTaskBelow/:LoanCaseId",component:<AddBelowTask/>},
{path:"/addTaskAbove/:LoanCaseId",component:<CreateTaskAboveOfSequenceNo/>},
{path:"/addTempSubTask/:id",component:<AddTempSubTask/>},
{path:"/addTask",component:<AddTask/>},
{path:"/taskTemplates",component:<TaskTemplate/>},
{path:"/addTaskTemplates",component:<AddTaskTemplate/>},
{path:"/branchMaster",component:<Branch/>},
{path:"/addBranch",component:<AddBranch/>},
{path:"/addLoanStage",component:<AddLoanStages/>},
{path:"/addLoanTask",component:<AddLoanTask/>},
{path:"/EditCaseDetails/:LoanCaseId",component:<EditCaseDetail/>},
{path:"/EditContactDetails/:LoanCaseId",component:<EditContactDetail/>},
{path:"/EditPermanentAddress/:customerId",component:<EditPermanentAddresses/>},
{path:"/EditCurrentAddress/:customerId",component:<EditCurrentAddresses/>},
{path:"/EditSiteAddress/:LoanCaseId",component:<EditSiteAddresses/>},
{path:"/EditOfficeAddress/:customerId",component:<EditOfficeAddresses/>},
{path:"/EditApplicantDetails/:LoanCaseId",component:<EditApplicantDetail/>},
{path:"/deletedLeads",component:<DeletedLeads/>},
{path:"/ViewDeletedLead/:LeadID",component:<ViewDeletedLead/>},
{path:"/archivedLeads",component:<ArchivedLead/>},
{path:"/ViewArchivedLead/:LoanCaseId",component:<ViewArchivdLeads/>},
{path:"/userMgmtPool",component:<UserManagement/>},
{path:"/addNewUser",component:<AddUser/>},
{path:"/editUser/:userId",component:<UpdateUser/>},
{path:"/viewUser/:userId",component:<ViewUsers/>},
{ path: "/apps-crm-leads", component: <CrmLeads /> },
{ path: "/addLoanTasks", component: <AddTemplateTasks/> },
{ path: "/viewTemplate/:templateId", component: <ViewTemplate/> },
 
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout/>},
  { path: "/login", component: <Login /> },
   {path:"/ForgetPWD",component:<ForgotPassWord/>},
  {path:"/vefifyCode/:email",component:<VerificationCode/>},
  {path:"/resetPassword",component:<ResetPassword/>},
  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

];

export { authProtectedRoutes, publicRoutes ,executiveRoutes};