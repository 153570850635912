import React,{Fragment} from 'react';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import {  toast } from 'react-toastify';
const initialValues= {     
    bankName:"",
}

export default function AddBank() {
    const [isSubmitted, setSubmitted]=useState(false);
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [partner,setPartner]=useState([]);
    const navigate = useNavigate();
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/addBank',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
    
    },
   });
   const navigateToPrevious=()=>{
    navigate('/addBank');
   }
   const loadLead = async () => {
    const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBank`);
    setPartner(result.data);
    setFilterLeads(result.data);
  };

 console.log("loanStages:",partner );
  useEffect(() => {
    loadLead();
  }, []);
  useEffect(() => {
    const result=filterLeads.filter(leads=>{
      return leads.partner?.toLowerCase().match(search?.toLowerCase())
    });
    setPartner(result);
    // setSearch(result);
  }, [search]);
  return (
    <Fragment>
         {
       isSubmitted ? navigate(`/bankmgmt`) &
       toast.success('Bank Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                   <form onSubmit={handleSubmit}>
                   <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center cardHeader">
                        <span id="assignowner1">Add New Bank</span>
                        </div>
                        <div className="card-body">
                          <div className="row mt-2">
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Bank Name</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                         type="text"
                         name="bankName"
                          value={values.bankName}
                         onChange={handleChange}
                          onBlur={handleBlur} 
                          required
                          className="form-control sm"
                          placeholder="Enter Bank Name"
                        /> 
                      </div>
                      <div className="col-md-3 mt-2"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="cancelButton" onClick={navigateToPrevious}>Cancel</Button>
                        <Button color="primary" type="submit" className="submitButton">Add Bank</Button>
                        </div>
                        </div>
                            </div>
                        </div>
                   </form>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}

