import React,{Fragment} from 'react';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import csc from "country-state-city";
import Select from "react-select";
import {  toast } from 'react-toastify';


export default function AddBankBranch() {
    const [isSubmitted, setSubmitted]=useState(false);
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [partner,setPartner]=useState([]);
    const navigate = useNavigate();
    const[bankData,setBankData]=React.useState({
     
      bank:{
        bankId:'',
      },
      bankBranchName:'',
      branchEmailID:'',
      bankManagerName:'',
      bankManagerPrimaryNumber:'',
      bankManagerSecondaryNumber:'',
      bankManagerEmailID:'',
      branchCode:'',
      country:'',
      countryId:'',
      state:'',
      stateId:'',
      cityId:'',
      city:'',
      pinCode:"",
      dsaCode:'',
      
    })
 


   const [banks,setBanks] = useState({});
   useEffect(() => {
     const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
     axios
       .get(d6)
       .then((response) => {
         console.log("Bank List response :", response.data);
 
         setBanks(response.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, []);

   const handleChangeForBank =(e)=>{
    setBankData({
        ...bankData,
        bank: {
          bankId:e.target.value
          },
    })
  }

   const loadLead = async () => {
    const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBank`);
    setPartner(result.data);
    setFilterLeads(result.data);
  };

 console.log("loanStages:",partner );
  useEffect(() => {
    loadLead();
  }, []);
  useEffect(() => {
    const result=filterLeads.filter(leads=>{
      return leads.partner?.toLowerCase().match(search?.toLowerCase())
    });
    setPartner(result);
    // setSearch(result);
  }, [search]);

  const countries = csc.getAllCountries();
  console.log(csc.getAllCountries());

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const updatedStates = (countryId) =>
  csc
    .getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.name, ...state }));

const updatedCities = (stateId) =>
  csc
    .getCitiesOfState(stateId)
    .map((city) => ({ label: city.name, value: city.name, ...city }));

const handleChange=(e)=>{
  setBankData({
    ...bankData,
    [e.target.name]: e.target.value,
  })
 }
const handleSubmit=(e)=>{
  e.preventDefault();
  axios.post('https://pradhanportal.com/loanApplication/master/addBankBranh',bankData).then((response)=>{
    console.log(response);
    setSubmitted(true);
  }).catch((err)=>{
    console.error(err);
  });
 }

 const navigateToPrevious=()=>{
  navigate('/bankBranchmgmt');
 }
  return (
    <Fragment>
      {/* <pre>{JSON.stringify(bankData)}</pre> */}
         {
       isSubmitted ? navigate(`/bankBranchmgmt`) &
       toast.success('Bank Branch Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                   <form onSubmit={handleSubmit}>
                   <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center cardHeader">
                        <span id="assignowner1">Add New Branch</span>
                        </div>
                        <div className="card-body">
                          <div className="row mt-2">
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                              id='mandatory'
                            >
                              Bank
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bank.bankId"
                              onChange={handleChangeForBank}
                              value={bankData.bank?.bankId}
                            >
                              <option value="">Please Select</option>
                              {banks?.length > 0 ? (
                                <Fragment>
                                  {banks.map((bank, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bank?.bankId}
                                      >
                                        {bank?.bankName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end"id='mandatory'>Branch Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankBranchName"
                          value={bankData.bankBranchName}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Name"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="branchEmailID"
                          value={bankData.branchEmailID}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Email ID"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerName"
                          value={bankData.bankManagerName}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Name"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Primary Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerPrimaryNumber"
                          value={bankData.bankManagerPrimaryNumber}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Primary Number"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Secondary Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerSecondaryNumber"
                          value={bankData.bankManagerSecondaryNumber}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Secondary Number"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Branch Manager Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="bankManagerEmailID"
                          value={bankData.bankManagerEmailID}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Email ID"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">DSA Code</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="dsaCode"
                          value={bankData.dsaCode}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Branch Manager Email ID"
                        /> 
                      </div>
                      <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">Country</span>
            </div>
            <div className="col-md-6 mt-2">
              <Select
                id="country"
                name="country"
                label="country"
                className="countrydrp"
                rules={{ required: true }}
                options={updatedCountries}
                onChange={(event) =>setBankData({...bankData,country: event.name,countryId: event.id,countryCode: event.id,
                  })
                }
              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">State</span>
            </div>
            <div className="col-md-6 mt-2">
              <Select
                name="state"
                id="state"
                className="countrydrp"
                options={updatedStates(
                  bankData.countryId ? bankData.countryId : null
                )}
                onChange={(event) =>
                  setBankData({
                    ...bankData,
                    state: event.name,
                    stateId: event.id,
                    stateCode: event.id,
                  })
                }
              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end  ">City</span>
            </div>
            <div className="col-md-6 mt-2">
              <Select
                //required
                id="city"
                className="countrydrp"
                name="city"
                options={updatedCities(
                  bankData.stateId ? bankData.stateId : null
                )}
                onChange={(event) =>
                  setBankData({
                    ...bankData,
                    city: event.name,
                    cityId: event.id,
                    cityCode: event.id,
                  })
                }
              />
            </div>
            <div className="col-md-4 mt-3">
              <span className="d-flex justify-content-end ">PIN</span>
            </div>
            <div className="col-md-6 mt-2">
              <input
                type="number"
                name="pinCode"
                className="form-control"
                placeholder="Enter PIN Code"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                value={bankData.pinCode}
              />
            </div>
                      <div className="col-md-3 mt-2"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="cancelButton" onClick={navigateToPrevious}>Cancel</Button>
                        <Button color="primary" type="submit" className="submitButton">Add Branch</Button>
                        </div>
                        </div>
                        </div>
                    </div>
                   </form>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}

