import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
// import "../../assets/scss/pages/lead.css";
import DataTable from "react-data-table-component";
import axios from "axios";
import Tooltip from "rc-tooltip";
import { Pagination } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const initialValues= {     
    partnershipName:"",
}

export default function BankBranchMaster() {
  var renderTooltip = <span>Add New Bank Branch</span>;

  const navigateToSettings = () => {
    navigate(`/applicationSetting`);
  };
  const navigateToAddStage=()=>{
    navigate(`/addbankbranch`)
  }

  const [isSubmitted, setSubmitted]=useState(false);
 

    const navigate = useNavigate();
  
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
    const toggle = () => setModal(!modal);
    const [modal, setModal] = React.useState(false); 
    const [loanStages,setLoanStages]=useState([]);
    const [selected, setSelected] = useState([]);
    const [autofetch, setAutofetch] = useState([]);
const [formData,setFormData]= useState({
  loanStage:"",raisedFor:""
})

const[dataAll,setDataAll]=useState([]);
   
    
     
const loadLead = async (value) => {
  if (value != null) {
    const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBankBranch`)
    setDataAll(result.data);
    console.log("getListOfAllCases",result.data)
  } else {
    const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllBankBranch`)
    setDataAll(result.data);
    console.log("getListOfAllCases",result.data)
  }
};

useEffect(() => {
  loadLead();
}, []);
const [showRecord, setshowRecord] = useState(10);

const  handleChanegeShowRec = (e) => {
  const { name, value, type, checked } = e.currentTarget;

  setshowRecord(e.currentTarget.value);

  loadLead(value);
 }
//for table records per page
const [recordCount, setrecordCount] = useState(10);


//for pagination

const [pageData, setPageData] = useState([]);
const [page, setPage] = useState(1);
const [pageCount, setPageCount] = useState(10);
// handle next
const handleNext = () => {
  if (page === pageCount) return page;
  setPage(page + 1)
}
const authUser = JSON.parse(localStorage.getItem("user"));
    const obj=authUser?.role?.role;
    const roleId=authUser?.role?.roleId;

    const [privilegedata, setPrivilegeData] = useState([]);

    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllRolePrivilegeFlagsByRoleId?roleId=${roleId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("setPrivilegeData response :", response.data);
          setPrivilegeData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
// handle previous
const handlePrevios = () => {
  if (page === 1) return page;
  setPage(page - 1)
}

useEffect(() => {
loadLead()
}, [page])

useEffect(() => {
  const pagedatacount = Math.ceil(dataAll?.length / showRecord);
  setPageCount(pagedatacount);

  if (page) {
      const LIMIT = showRecord;
      const skip = LIMIT * page // 5 *2 = 10
       const dataskip = dataAll?.slice(page === 1 ? 0 : skip - LIMIT, skip);
      setPageData(dataskip)
  }
}, [search,dataAll])
useEffect(() => {
  const result=data.filter(leads=>{
    return leads.loanStage?.toLowerCase().match(search?.toLowerCase())
  });
  setDataAll(result);
  // setSearch(result);
}, [search]);

const createdDate=(dataAll)=>{
  return moment(dataAll.createdDate)
  .format("DD-MMM-YYYY")
}
    useEffect(() => {
      const result=data.filter(leads=>{
        return leads.loanStage?.toLowerCase().match(search?.toLowerCase())
      });
      setLoanStages(result);
      // setSearch(result);
    }, [search]);

  const columns = useMemo(
    () => [
      
      {
        name: "Branch ID",
        selector: "bankBranchId",
      },
      {
        name: "Bank Name",
        selector: "bank.bankName",
       
      },
      {
        name: "Branch Name",
        selector: "bankBranchName",
       
      },
      {
        name: "Branch City",
        selector: "",
       
      },
      {
        name: "Branch Location",
        selector: "",
       
      },
      
    ],
    []
  );

  const tableHeaderstyle={
    headCells:{
        style:{
            fontWeight:"500",
            fontSize:"14px",
            backgroundColor:"#032a6b",
            color:"#ffff"
           
        },
    },
   }
   const myNewTheme= {
    rows: {
      fontSize: '10px',
    }
  }
  return (
    <fragment>
      <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
                    {/* <span className='float-start'><img src={leftChevron} width = "30" height = "25" alt='' className='mx-4'  onClick={navigateToSettings}/></span> */}
              <div className='d-inline-block'>
                <h3> Bank Branches</h3>
              </div>
              <div className='d-inline-block'>
              {/* <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToAddStage} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip> */}
              </div>
            </div>
              </Col>
              <Col sm={5}></Col>
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                 dataAll?.length > 0 ?(
                    <>
                    <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
          <th scope="col" className="tableroww">Sr.No.</th>
       <th scope="col" className="tableroww">Branch ID</th>
         <th scope="col" className="tableroww">Bank Name</th>
         <th scope="col" className="tableroww">Branch Name</th>
         <th scope="col" className="tableroww">Branch Manager Number</th>
       </tr>
     </thead>
     <tbody>
      
      {
          pageData?.map((dataAll,index)=>( 
              <tr key={index}>
              <th scope='row' className="tableroww1">{index + 1}</th>
              {
                 privilegedata[1]?.privilegeId === 102 && privilegedata[1]?.privilageFlag === true?
                 <Fragment>
<td className="tableroww1 capitalizetxxxt">
              <Link id="leadid1" to={`/viewBankBranchMaster/${dataAll.bankBranchId}`} >
        {dataAll.generatedId}
           </Link>
              </td>
                 </Fragment>:<Fragment>
                 <td className="tableroww1 capitalizetxxxt">
              <Link id="leadid1" to={""} >
        {dataAll.generatedId}
           </Link>
              </td>
                 </Fragment>
              }
              
              <td className="tableroww1 capitalizetxxxt">{dataAll.bank?.bankName}</td>
              <td className="tableroww1 capitalizetxxxt">{dataAll?.bankBranchName}</td>
              <td className="tableroww1 capitalizetxxxt">{dataAll?.bankManagerPrimaryNumber}</td>
              </tr>
  
  
           ))
      } 
    </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            .fill(null)
                            .map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                     </>
                   ):<>
                   <div>No Records Available</div></>
                }
                  </div>
                  </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
    </fragment>
  )
}


