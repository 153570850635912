import React from 'react';
import { Col, Container, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useParams } from 'react-router-dom';
import UpdateLeads from './UpdateLeads';
import "../../assets/scss/pages/lead.css";

const UpdateLead = () => {
    const { LeadID } = useParams();
    const viewLeadLink = `/#/ViewLead/${LeadID}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Edit Lead</h4>
                                <Breadcrumb listTag="div" className="page-title-right">
                                    <BreadcrumbItem href="/#/LeadCreation-Lead" tag="a">
                                        Leads
                                    </BreadcrumbItem>
                                    <BreadcrumbItem href="/#/LeadCreation-Lead" tag="a">
                                        Free Pool
                                    </BreadcrumbItem>
                                    <BreadcrumbItem tag="a" href={viewLeadLink}>
                                        View Lead
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active tag="span">
                                        Edit Lead
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <UpdateLeads />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UpdateLead;
