import React, { Fragment, useMemo } from "react";
import Tooltip from "rc-tooltip";
import TableRows from "./TableRows";
import { Col, Container, Row, Card, CardHeader } from "reactstrap";
import { useState } from "react";
import axios from "axios";
import setting from "../../../assets/images/setting.png";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import TaskRow from "./TaskRows";
const initialValues = {
 templateId:'',
templateName:'',
caseType:'',
category:'',
subCategory:'',
duration:''}
export default function AddTemplate() {
  const [rowsData, setRowsData] = useState([]);
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);
  const addTableRows = (e,index) => {
    e.preventDefault();
    const rowsInput = {
      templateId:'',
      key:index != undefined ?index+1:0,
      loanStage: {
        loanStageID: "",
      },
      loanTaskVO:{
        loanTaskId:'',
     },
      subTaskVO: {
        subTaskId: "",
      },
      duration: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
// const handleChangeForTemplate=(e)=>{
//   setTemplateData({
//     ...templateData,
//     [e.target.name]:[e.target.value]
//   })
// }
//   const handleChange = (index, evnt) => {
//     const { name, value } = evnt.target;
//     const rowsInput = [...rowsData];
//     rowsInput[index][name] = value;
//     setRowsData(rowsInput);
//   };
  console.log("Row Data====", rowsData);
  console.log("Row Data Key====", rowsData.key);
  const onSubmitCreateTemp=()=>{
    console.log("final Submit :",rowsData)
  }
  const updateParentState=(newData)=>{
    console.log("Parent Data Before:",rowsData)
    console.log("Data for Update",newData);
    const  updatedItems=rowsData.map(item=>{
      if(item.key === newData.key){
        return {...newData}
      }
      return item;
    })
    setRowsData(updatedItems)
    console.log("Before Submit Row  Data", rowsData);
    //TO DO Logic for update rows data based on index
    console.log("Parent Data updatedItems:",updatedItems)
  }
  
  console.log("Parent Data After:",rowsData)
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
  useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
        axios
          .post("https://pradhanportal.com/loanApplication/master/addTaskTemplate", values)
          .then((response) => {
            console.log(response);
            console.log("Submit Data", values);
            if(response.data !==null){
              rowsData.map((data,index)=>{
                data.templateId = response.data;
               
                  
               
              })
              submitRowData();
            }
            setSubmitted(true);
          })
          .catch((err) => {
            console.error(err);
          });
      
    },
  });
 // console.log("Before Submit Row  Data", rowsData);
  const submitRowData=()=>{
    // useEffect(()=>{
      axios
          .post("https://pradhanportal.com/loanApplication/master/addTemplateTask", rowsData)
          .then((response) => {
            console.log(response);
            console.log("Submit Row  Data", rowsData);
          })
          .catch((err) => {
            console.error(err);
          });
  // }, [])
    
  }

  const navigateToTemplate =()=>{
    navigate("/taskTemplates");
  }
  return (
    <Fragment>
      {/* <pre>{JSON.stringify(rowsData)}</pre> */}
       {
       isSubmitted ? navigate(`/taskTemplates`) &
       toast.success('Template Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <form onSubmit={handleSubmit}>
                <div className="bg-primary d-flex justify-content-center cardHeader">
                  New Templates
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Template Name
                            </span>
                          </div>
                          <div className="col-md-8 mt-2">
                            <input
                              type="text"
                              name="templateName"
                              className="form-control sm"
                              placeholder="Enter Template Name"
                              required
                                  onChange={handleChange}
                                  value={values.templateName}
                            />
                          </div>
                          <div className="col-md-3 mt-4">
                            <span className="d-flex justify-content-end  ">
                              Case Type
                            </span>
                          </div>
                          <div className="col-md-8 mt-3">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="caseType"
                              required
                              onChange={handleChange}
                              value={values.caseType}
                            >
                              <option selected>Please Select</option>
                              <option value="Loan">Loan</option>
                              <option value="Insurance">Insurance</option>
                            </select>
                          </div>
                          <div className="col-md-3 mt-4">
                            <span className="d-flex justify-content-end  ">
                              Category
                            </span>
                          </div>
                          <div className="col-md-8 mt-3">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="category"
                              required
                              onChange={handleChange}
                              value={values.category}
                            >
                              <option selected>Please Select</option>
                              <option value="Home Loan">Loan</option>
                              <option value="Car Loan"> Car Loan</option>
                            </select>
                          </div>
                          <div className="col-md-3 mt-4">
                            <span className="d-flex justify-content-end  ">
                              Sub Category
                            </span>
                          </div>
                          <div className="col-md-8 mt-3">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="subCategory"
                              required
                              onChange={handleChange}
                              value={values.subCategory}
                            >
                              <option selected>Please Select</option>
                              <option value="New Home Loan">
                                New Home Loan
                              </option>
                              <option value="Top Up & Take Over">
                                Top Up & Take Over
                              </option>
                            </select>
                          </div>
                          <div className="col-md-3 mt-3">
                            <span className="d-flex justify-content-end">
                              Duration
                            </span>
                          </div>
                          <div className="col-md-8 mt-2">
                            <input
                              type="number"
                              className="form-control sm"
                              placeholder="Enter New Task Name"
                              name="duration"
                              onChange={handleChange}
                              value={values.duration}
                            />
                          </div>
                          <div className="col-md-1 mt-3">Days</div>
                          <Container fluid>
                            <Row>
                              <Col lg={12}>
                                <Card id="leadsList">
                                  <CardHeader className="border-0">
                                  </CardHeader>
                                  <table className="table table-hover table-striped text-center ">
                                    <thead className="bg-primary text-white">
                                      <tr>
                                        <th>
                                          <span style={{marginRight:"50px"}}>Id</span>
                                          <span style={{marginRight:"230px"}}>Stage Name</span>
                                          <span style={{marginRight:"230px"}}>Task Name</span>
                                          <span style={{marginRight:"100px"}}>Sub Task Name</span>
                                          <span>Duration</span>
                                        </th>
                                        <th>
                                          <button
                                            className="btn btn-outline-success btn-sm"
                                            onClick={addTableRows}
                                          >
                                            +
                                          </button>
                                        </th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rowsData?.map((data, index) => {
                                       
                                        return (
                                          <tr key={index}>
                                            <td>
                                            <TaskRow updateParentState={updateParentState} index={index} />
                                            </td>
                                            <td>
                                              <button className="btn btn-outline-success btn-sm" onClick={(e)=>(addTableRows(e,index))}>+</button>
                                            </td>
                                            <td >
                                              <i
                                                class="bi bi-trash  btn-lg mt-2"
                                                id="trashbtn"
                                                onClick={() =>
                                                  deleteTableRows(index)
                                                }
                                              ></i>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </Card>
                              </Col>
                            </Row>
                          </Container>
                          <div className="col-md-4"></div>
                          <div className="col-md-5 mt-4">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                              <Button
                                color="secondary"
                                className="cancelButton"
                                onClick={navigateToTemplate}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="primary"
                                type="submit"
                                className="submitButton"
                              >
                                Create Template
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
}
    </Fragment>
  );
}
