import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumb,BreadcrumbItem} from 'reactstrap';
import AddKYCDetails from './AddKYCDetails';
import { useParams } from 'react-router-dom';


//import Components






const AddKYCDetail = () => {
    const {LoanCaseId} = useParams();
            const viewLoanCaseLink = `/#/viewLoanCase/${LoanCaseId}`;
document.title="Pradhan Portal";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row>
                <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Loan Cases</h4>
                   <Breadcrumb listTag="div"  className="page-title-right ">
                   <BreadcrumbItem
                         href="/#/loanCases"
                         tag="a"
                  >
                   Loan Cases
                </BreadcrumbItem>
                  <BreadcrumbItem
                         href={viewLoanCaseLink}
                         tag="a"
                  >
                  View Loan Case
                </BreadcrumbItem>
                    <BreadcrumbItem
                         active
                         tag="span"
                    >
                     Add KYC Details
                 </BreadcrumbItem>
           </Breadcrumb>
       </div>
                </Col>
               </Row>
                    <Row>
                        <Col lg={12}>
                    <AddKYCDetails/>
                            {/* <UpgradeAccountNotise />
                            <Widget /> */
                            
                            }
                        </Col>
                        {/* <CreateLead/> */}
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddKYCDetail;