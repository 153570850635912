import React,{Fragment,useState,useEffect} from 'react';
import { useParams,useNavigate} from 'react-router-dom';
import { Button} from "reactstrap" ;
import axios from 'axios';
import {toast} from 'react-toastify';
export default function AddAddressDetails() {
    const[isPermenent,setIsPermenent]=useState(false);
    const[isCurrent,setISCcurrent]=useState(false);
    const[isSiteAddress,setIsSiteAddress]=useState(false);
    const[isOffice,setIsOffice]=useState(false);
    const [isSubmitted, setSubmitted]=useState(false);
    const{LoanCaseId}=useParams();
    console.log("LoanCaseId",LoanCaseId);
    const navigate = useNavigate();
    const[siteAddress,setSiteAddress]=useState({
      LoanCaseId:'',
    siteAddressLine1:'',
    siteAddressLine2:'',
    siteLandmark:'',
    sitePin:'',
    siteCountry:{
      countryID:'',
      countryName:'',
    },
    siteState:{
      stateID:'',
      state:''
    },
    siteCity:{
      cityID:'',
      cityName:''
    }
    })
    siteAddress.LoanCaseId = LoanCaseId;

const[country,setCountry]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllCountry`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setCountry(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);


const[state,setState]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllState`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setState(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);


const[city,setCity]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllCity`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setCity(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);

const handleChangeForSiteAddress = (e) => {  
  setSiteAddress({ 
      ...siteAddress,
    [e.target.name]: e.target.value       
  }); 
};

const handleChangeForCountry=(e)=>{
  setSiteAddress({ 
    ...siteAddress,
    siteCountry:{
      countryID: e.target.value } 
}); 
}

const handleChangePermenentState=(e)=>{
  setSiteAddress({
    ...siteAddress,
    siteState:{
      stateID:e.target.value
    },
  });
}

const handleChangePermenentCity=(e)=>{
  setSiteAddress({
    ...siteAddress,
    siteCity:{
      cityID:e.target.value
    },
  });
}
const submitSiteAddress=(e)=>{
  e.preventDefault();
  axios.put('https://pradhanportal.com/loanApplication/master/editLoanCaseSiteAddress',siteAddress).then((response)=>{
          console.log(response);
          toast.success(' Address Updated successfully!', {
            position: toast.POSITION.TOP_RIGHT
            }) 
          setSubmitted(true);
        }).catch((err)=>{
          console.error(err);
        });
  
};
  return (
    <Fragment>
      {/* <pre>{JSON.stringify(siteAddress)}</pre> */}
    <div className="container">
            <div className="row">
                <div className="col-md-12">
                   <div className="card">
                   <form onSubmit={submitSiteAddress}>
                    <div className="row">
                  <div className="col-md-12 mt-3">
                  <span className='customerDetails'>Add Address Details</span>
                   <div className="row">
                  
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end" id="mandatory"> Address Type</span>
                        </div>
                        <div className="col-md-9 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="address"
                              id="inlineRadio10"
                              onClick={()=>{setIsPermenent(true);setISCcurrent(false);setIsSiteAddress(false);setIsOffice(false)}}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio10"
                            >
                              Permanent Address
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="address"
                              id="inlineRadio11"
                              onClick={()=>{setIsPermenent(false);setISCcurrent(true);setIsSiteAddress(false);setIsOffice(false)}}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio11"
                            >
                              Current Address
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="address"
                              id="inlineRadio12"
                              onClick={()=>{setIsPermenent(false);setISCcurrent(false);setIsSiteAddress(true);setIsOffice(false)}}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio12"
                            >
                              Site Address
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="address"
                              id="inlineRadio13"
                              onClick={()=>{setIsPermenent(false);setISCcurrent(false);setIsSiteAddress(false);setIsOffice(true)}}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio13"
                            >
                              Office/Business Address
                            </label>
                          </div>
                        </div>
                        <div className="co-md-3"></div>
                       {
                        isPermenent &&
                        <Fragment>
                             <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end"> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end address">Permanent Address</span>
                        </div>
                        <div className="col-md-8 mt-2"></div>
                          <div className="col-md-3 mt-4">
                        <span className="d-flex justify-content-end">Address Line 1</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <input
                          type="text"
                          name="pAddressLine1"
                          className="form-control sm"
                          placeholder="Enter Address Line 1"
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Address Line 2</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="pAddressLine2"
                          className="form-control sm"
                          placeholder="Enter Address Line 2"
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Landmark</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="pLandmark"
                          className="form-control sm"
                          placeholder="Enter Landmark"
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Country</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">State</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">City</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='pCity.cityID'
                        >
                          <option value="">Please Select</option>
                         
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">PIN</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="pPIN"
                          className="form-control sm"
                          placeholder="Enter PIN"
                        />
                      </div><div className='col-md-3'></div>
                        </Fragment>
                       }
                       {
                        isCurrent && <Fragment>
                            <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end"> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                               <div className="row">
                    <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end address">Current Address</span>
                        </div>
                    </div>
                    <div className="row">
                   
                   <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">Address Line 1</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="text"
                   name="cAddressLine1"
                   className="form-control sm"
                   placeholder="Enter Address Line 1"
                 />
               </div><div className='col-md-3'></div>
               <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">Address Line 2</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="text"
                   name="cAddressLine2"
                   className="form-control sm"
                   placeholder="Enter Address Line 2"
                 />
               </div><div className='col-md-3'></div>
               <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">Landmark</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="text"
                   name="cLandMark"
                   className="form-control sm"
                   placeholder="Enter Landmark"
                 />
               </div><div className='col-md-3'></div>
               <div className="col-md-3 mt-3">
               <span className="d-flex justify-content-end ">Country</span>
                   </div>
                   <div className="col-md-6 mt-2">
                   <select
                   class="form-select form-select-md"
                   aria-label=".form-select-md example"
                   name='cCountry.countryID'
                 >
                   <option value="">Please Select</option>
                 
                 </select>
                   </div><div className='col-md-3'></div>
                   <div className="col-md-3 mt-3">
               <span className="d-flex justify-content-end  ">State</span>
                   </div>
                   <div className="col-md-6 mt-2">
                   <select
                   class="form-select form-select-md"
                   aria-label=".form-select-md example"
                 >
                   <option value="">Please Select</option>
                  
                 </select>
                   </div><div className='col-md-3'></div>
                   <div className="col-md-3 mt-3">
               <span className="d-flex justify-content-end ">City</span>
                   </div>
                   <div className="col-md-6 mt-2">
                   <select
                   class="form-select form-select-md"
                   aria-label=".form-select-md example"
                   name='cCity.cityID'
                 >
                   <option value="">Please Select</option>
                  
                 </select>
                   </div><div className='col-md-3'></div>
                   <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">PIN</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="number"
                   name="cPIN"
                   className="form-control sm"
                   placeholder="Enter PIN"
                 />
               </div><div className='col-md-3'></div>
             </div>
                        </Fragment>
                       }
                       {
                        isSiteAddress &&
                        <Fragment>
                           <div className="row">
                          
                               <div className="row">
                    <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-center address">Site Address</span>
                        </div>
                    </div>
                    <div className="row">
                   
                   <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">Address Line 1</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="text"
                   name="siteAddressLine1"
                   className="form-control sm"
                   placeholder="Enter Address Line 1"
                   value={siteAddress.siteAddressLine1}
                   onChange={handleChangeForSiteAddress}
                 />
               </div><div className='col-md-3'></div>
               <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">Address Line 2</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="text"
                   name="siteAddressLine2"
                   className="form-control sm"
                   placeholder="Enter Address Line 2"
                   value={siteAddress.siteAddressLine2}
                   onChange={handleChangeForSiteAddress}
                 />
               </div><div className='col-md-3'></div>
               <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">Landmark</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="text"
                   name="siteLandmark"
                   className="form-control sm"
                   placeholder="Enter Landmark"
                   value={siteAddress.siteLandmark}
                   onChange={handleChangeForSiteAddress}
                 />
               </div><div className='col-md-3'></div>
               <div className="col-md-3 mt-3">
               <span className="d-flex justify-content-end ">Country</span>
                   </div>
                   <div className="col-md-6 mt-2">
                   <select
                   class="form-select form-select-md"
                   aria-label=".form-select-md example"
                   name='siteCountry.countryID'
                   value={siteAddress.siteCountry?.countryID}
                   onChange={handleChangeForCountry}
                 >
                   <option value="">Please Select</option>
                   {
                     country?.length > 0 ? <Fragment>
                       {
                         country.map((type,index)=>{
                           return(
                             <option value={type?.countryID}key={index}>{type.countryName}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                 </select>
                   </div><div className='col-md-3'></div>
                   <div className="col-md-3 mt-3">
               <span className="d-flex justify-content-end  ">State</span>
                   </div>
                   <div className="col-md-6 mt-2">
                   <select
                   class="form-select form-select-md"
                   aria-label=".form-select-md example"
                   name='siteState.stateID'
                   onChange={handleChangePermenentState}
                   value={siteAddress.siteState?.stateID}
                 >
                   <option value="">Please Select</option>
                   {
                     state?.length > 0 ? <Fragment>
                       {
                         state.map((type,index)=>{
                           return(
                             <option value={type?.stateID}key={index}>{type.state}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                 </select>
                   </div><div className='col-md-3'></div>
                   <div className="col-md-3 mt-3">
               <span className="d-flex justify-content-end  ">City</span>
                   </div>
                   <div className="col-md-6 mt-2">
                   <select
                   class="form-select form-select-md"
                   aria-label=".form-select-md example"
                   name='siteCity.cityID'
                   onChange={handleChangePermenentCity}
                   value={siteAddress.siteCity?.cityID}
                 >
                   <option value="">Please Select</option>
                   {
                     city?.length > 0 ? <Fragment>
                       {
                         city.map((cities,index)=>{
                           return(
                             <option value={cities?.cityID}key={index}>{cities.cityName}</option>
                             
                           );
                         })
                       }
                     </Fragment>:null
                   }
                 </select>
                   </div><div className='col-md-3'></div>
                   <div className="col-md-3 mt-3">
                 <span className="d-flex justify-content-end">PIN</span>
               </div>
               <div className="col-md-6 mt-2">
                 <input
                   type="number"
                   name="sitePin"
                   className="form-control sm"
                   placeholder="Enter PIN"
                   value={siteAddress.sitePin}
                   onChange={handleChangeForSiteAddress}
                 />
               </div><div className='col-md-3'></div>

             </div>
            
             </div>
                        </Fragment>
                       }
                       {
                        isOffice && <Fragment>
                            <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end"> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end address">Office/Business Address</span>
                        </div>
                        <div className="col-md-8 mt-2"></div>
                          <div className="col-md-3 mt-4">
                        <span className="d-flex justify-content-end">Address Line 1</span>
                      </div>
                      <div className="col-md-6 mt-3">
                        <input
                          type="text"
                          name="pAddressLine1"
                          className="form-control sm"
                          placeholder="Enter Address Line 1"
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Address Line 2</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="pAddressLine2"
                          className="form-control sm"
                          placeholder="Enter Address Line 2"
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Landmark</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="pLandmark"
                          className="form-control sm"
                          placeholder="Enter Landmark"
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Country</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">State</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                        >
                          <option value="">Please Select</option>
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">City</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='pCity.cityID'
                        >
                          <option value="">Please Select</option>
                         
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">PIN</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="number"
                          name="pPIN"
                          className="form-control sm"
                          placeholder="Enter PIN"
                        />
                      </div><div className='col-md-3'></div>
                        </Fragment>
                       }
                      
                         <div className="row mt-3 mb-3">
                      <div className="col-md-10">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                          <button
                          onClick={''}
                            class="btn btn-secondary me-md-2"
                            id="Cancelbtn1"
                            type="button"
                          >
                            Cancel
                          </button>
                          <button class="btn btn-primary" type="submit" id="Cancelbtn2">
                           Add Address
                          </button>
                        </div>
                      </div>
                    </div>
                   </div>
                   </div>
                   </div>
                   </form>
                   </div>
                   </div>
                   </div>
                   </div>
    </Fragment>
  )
}
