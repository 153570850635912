import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumb,BreadcrumbItem} from 'reactstrap';
import ViewBankBranchMasterDetails from './ViewBankBranchMasterDetails';
//import Components

const ViewBankBranchMaster = () => {
document.title="Pradhan Portal";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row>
                <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">View Bank Branch</h4>
                   <Breadcrumb listTag="div"  className="page-title-right ">
                  <BreadcrumbItem
                        
                         tag="a"
                  >
                   Bank Branches
                </BreadcrumbItem>
                    <BreadcrumbItem
                         active
                         tag="span"
                    >
                   View Bank Branch
                 </BreadcrumbItem>
           </Breadcrumb>
       </div>
                </Col>
               </Row>
                    <Row>
                        <Col lg={12}>
                  <ViewBankBranchMasterDetails/>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewBankBranchMaster;