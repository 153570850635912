import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Input } from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
import { Pagination } from "react-bootstrap";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SubtypeManagement() {
  const navigate = useNavigate();

  // State Variables
  const [dataAll, setDataAll] = useState([]); // All records fetched from API
  const [pageData, setPageData] = useState([]); // Data to display on the current page
  const [recordCount, setRecordCount] = useState(0); // Total number of records
  const [showRecord, setShowRecord] = useState(10); // Records per page
  const [page, setPage] = useState(1); // Current page
  const [pageCount, setPageCount] = useState(1); // Total pages
  const [search, setSearch] = useState(""); // Search query

  // Tooltip
  const renderTooltip = <span>Add New Loan Sub Type</span>;

  // Navigation Handlers
  const navigateToSettings = () => {
    navigate(`/applicationSetting`);
  };

  const navigateToAddStage = () => {
    navigate(`/addSubTypes`);
  };

  // Fetch data from API
  const loadLead = async () => {
    try {
      const result = await axios.get(
        `https://pradhanportal.com/loanApplication/master/getAllSubType`
      );
      setDataAll(result.data);
      setRecordCount(result.data.length); // Set the total record count
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle Records Per Page Change
  const handleChangeShowRec = (e) => {
    const newShowRecord = parseInt(e.target.value, 10);
    setShowRecord(newShowRecord);
    setPage(1); // Reset to the first page when changing the record count
  };

  // Update Page Count on Record Count or Page Size Change
  useEffect(() => {
    const totalPages = Math.ceil(recordCount / showRecord);
    setPageCount(totalPages);
  }, [recordCount, showRecord]);

  // Fetch Data When Component Mounts
  useEffect(() => {
    loadLead();
  }, []);

  // Slice Data for Current Page
  useEffect(() => {
    const startIndex = (page - 1) * showRecord; // Calculate start index
    const endIndex = startIndex + showRecord; // Calculate end index
    const currentPageData = dataAll.slice(startIndex, endIndex); // Slice data
    setPageData(currentPageData); // Set data for the current page
  }, [dataAll, page, showRecord]);

  // Filter and Paginate Data on Search
  useEffect(() => {
    const filteredData = dataAll.filter((item) =>
      item.loanStage?.toLowerCase().includes(search.toLowerCase())
    );
    setRecordCount(filteredData.length); // Update total record count
    setPage(1); // Reset to the first page
  }, [search]);

  // Handle Pagination Navigation
  const handleNext = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Format Created Date
  const createdDate = (dataItem) => {
    return moment(dataItem.createdDate).format("DD-MMM-YYYY");
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="leadsList">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <Col sm={3}>
                    <div className="d-flex align-items-center">
                      <div className="d-inline-block">
                        <h3>Loan Sub Types</h3>
                      </div>
                      <div className="d-inline-block">
                        <Tooltip placement="right" overlay={renderTooltip}>
                          <h2>
                            <i
                              onClick={navigateToAddStage}
                              className="bi bi-plus-square-fill btn btn-lg"
                            ></i>
                          </h2>
                        </Tooltip>
                      </div>
                    </div>
                  </Col>
                  <Col sm={5}></Col>
                  <Col sm={8} style={{ marginTop: "0px", paddingLeft: "30px" }}>
                    <div className="" style={{ float: "left" }}>
                      <select
                        id="inputState"
                        name="showrecc"
                        onChange={handleChangeShowRec}
                        value={showRecord}
                        className="selectdrpdn form-control"
                        style={{ height: "32px", marginTop: "4px" }}
                      >
                        <option value="10">Show 10</option>
                        <option value="25">Show 25</option>
                        <option value="50">Show 50</option>
                        <option value="100">Show 100</option>
                      </select>
                    </div>
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={3} style={{ marginTop: "0px", paddingRight: "30px" }}>
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="main">
                  {recordCount > 0 ? (
                    <>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="table-responsive"
                              style={{ overflowX: "auto", height: "600px" }}
                              id="tableData"
                            >
                              <table
                                className="table align-items-center table-flush table-hover"
                                id="dataTableHover"
                              >
                                <thead className="tableheader">
                                  <tr>
                                    <th scope="col" className="tableroww">
                                      Sr.No.
                                    </th>
                                    <th scope="col" className="tableroww">
                                      Loan Sub Type ID
                                    </th>
                                    <th scope="col" className="tableroww">
                                      Loan Type Name
                                    </th>
                                    <th scope="col" className="tableroww">
                                      Type Name
                                    </th>
                                    <th scope="col" className="tableroww">
                                      Sub Type Name
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pageData.map((dataItem, index) => (
                                    <tr key={index}>
                                      <th scope="row" className="tableroww1">
                                        {(page - 1) * showRecord + index + 1}
                                      </th>
                                      <td className="tableroww1 capitalizetxxxt">
                                        <Link
                                          id="leadid1"
                                          to={`/viewSubType/${dataItem.subTypeID}`}
                                        >
                                          {dataItem.generatedId}
                                        </Link>
                                      </td>
                                      <td className="tableroww1 capitalizetxxxt">
                                        {dataItem.leadTypeVO?.leadType}
                                      </td>
                                      <td className="tableroww1 capitalizetxxxt">
                                        {dataItem.typeVO?.type}
                                      </td>
                                      <td className="tableroww1 capitalizetxxxt">
                                        {dataItem?.subTypes}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div style={{ float: "right" }}>
                                <Pagination>
                                  <Pagination.Prev
                                    onClick={handlePrevious}
                                    disabled={page === 1}
                                  />
                                  {Array.from({ length: pageCount }).map(
                                    (_, index) => (
                                      <Pagination.Item
                                        key={index}
                                        active={page === index + 1}
                                        onClick={() => setPage(index + 1)}
                                      >
                                        {index + 1}
                                      </Pagination.Item>
                                    )
                                  )}
                                  <Pagination.Next
                                    onClick={handleNext}
                                    disabled={page === pageCount}
                                  />
                                </Pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>No Records Available</div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
