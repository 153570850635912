import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { Fragment } from "react";
import "../../assets/scss/pages/lead.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import validator from 'validator';
  
export default function LeadDetails({handleNext,addLeadDetails,handleBack,leadData,setLeadData,activeStep}) {
    const [isSubmitted, setSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const Initiator = JSON.parse(localStorage.getItem('user'));
  const initID =Initiator.userID;
  const [characterLimit] = useState(300);

  const handleChange=(e)=>{
    const {name,value,type}=e.currentTarget;
    setLeadData(preState =>({
        ...preState,
        [name]: value
      }))
  }
  const handleChangeForLeadType=(e)=>{
    setLeadData({
        ...leadData,
        leadTypeVO: {
            leadTypeID:e.target.value
          },
    })
  }
  const handleChangeForType =(e)=>{
    setLeadData({
        ...leadData,
        typesVO: {
            typeId:e.target.value
          },
    })
  }

  const handleChangeForSubType =(e)=>{
    setLeadData({
        ...leadData,
        subTypesVO: {
            subTypeID:e.target.value
          },
    })
  }

  const handleChangeInputForOwner =(e)=>{
    setLeadData({
        ...leadData,
        userVO: {
          userId:e.target.value
          },
    })
  }
  const usrId=leadData.userVO?.userId;
  console.log("userIDDDD",usrId);
  const [usr, setUsr] = useState({});
useEffect(() => {
  const d6 = `https://pradhanportal.com/loanApplication/master/getUserById?userId=${usrId}`;
  axios
    .get(d6)
    .then((response) => {
      console.log("response :", response.data);

      setUsr(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}, [usrId]);

    const [leadTypes, setLeadTypes] = useState([]);
    useEffect(() => {
      const d5 = `https://pradhanportal.com/loanApplication/master/getAllLeadType`;
      axios
        .get(d5)
        .then((response) => {
          console.log("response :", response.data);
  
          setLeadTypes(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);


   

    const ltID = leadData.leadTypeVO?.leadTypeID;
    console.log("leadtype", ltID);
    const [leadTyp, setLeadType] = useState({});
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getTypesByLeadID?leadTypeID=${ltID}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setLeadType(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [ltID]);
    const typeID = leadData.typesVO?.typeId;
    console.log("typeID", typeID);
    const [subtypes, setSubTypes] = useState({});
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getSubTypesByTypeID?typeID=${typeID}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data.data);
  
          setSubTypes(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [typeID]);

    const [partner,setPartner] = useState({});
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllPartner';
      axios
        .get(d6)
        .then((response) => {
          console.log("Partner List response :", response.data);
          setPartner(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    const handleChangeForPartner =(e)=>{
      setLeadData({
          ...leadData,
          partnerVO: {
            partnerId:e.target.value
            },
      })
    }
 const partnerId=leadData.partnerVO?.partnerId;
    const [partnerShip,setPartnerShip] = useState({});
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllCPBypartnerId?partnerId=${partnerId}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("Partner List response :", response.data);
          setPartnerShip(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [partnerId]);

    const handleChangeForPartnerShip =(e)=>{
      setLeadData({
          ...leadData,
          partnershipVO: {
            partnershipId:e.target.value
            },
      })
    }


    const [project,setProject] = useState({});
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllProject';
      axios
        .get(d6)
        .then((response) => {
          console.log("Partner List response :", response.data);
          setProject(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    const handleChangeForProject =(e)=>{
      setLeadData({
          ...leadData,
          projectVO: {
            projectId:e.target.value
            },
      })
    }

    const [user, setUser] = useState({});
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllUsers';
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setUser(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    const ownerID = leadData?.ownerVO?.ownerID;
    console.log("ownerID", ownerID);
    const [getOwner, setGetOwner] = useState("");
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getOwnerById?ownerID=${ownerID}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setGetOwner(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [ownerID]);

    const initiID = leadData?.initiatorVO?.initiatorID;
    console.log("initiIDi", initiID);

    const [iniBranch, setIniBranch] = useState({});
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getUserById?userId=${initiID}`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setIniBranch(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [initiID]);
    console.log(iniBranch);

    const handleChangeForInitiator =(e)=>{
      setLeadData({
          ...leadData,
          initiatorVO: {
            initiatorID:e.target.value
            },
      })
    }

    const handleChangeForGd =(e)=>{
      setLeadData({
          ...leadData,
          gdStatusVO: {
            gdStatusID:e.target.value
            },
      })
    }
    const handleInputChange =(e)=>{
      setLeadData({
       ...leadData,
       [e.target.name]:e.target.value
      });
   };
    const NavigateTOAssignModal = () => {
      toggle();
    };
    // Modal open state
const [modal, setModal] = React.useState(false);

// Toggle for Modal
const toggle = () => setModal(!modal);
const char = leadData?.leadNotes.length;
    
    const [status, setStatus] = useState("");
    useEffect(() => {
      const d6 = `https://pradhanportal.com/loanApplication/master/getAllGdStatus`;
      axios
        .get(d6)
        .then((response) => {
          console.log("response :", response.data);
  
          setStatus(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    const [error, setError] = useState(false);
    const submitFormData = (e) => {
       e.preventDefault();
       if (leadData.leadTypeVO?.leadTypeID=="" || leadData.leadTypeVO?.leadTypeID === null || leadData.leadTypeVO?.leadTypeID === undefined) {
        setError(true);
      }
      else if ( leadData.typesVO?.typeId=="") {
        setError(true);
      } 
      else if (leadData.subTypesVO?.subTypeID=="") {
        setError(true);
      }
      else if (leadData.leadTypeVO.leadTypeID === "2" && leadData.coverAmount=="") {
        setError(true);
      }
      else if (leadData.leadTypeVO.leadTypeID === "1" && leadData.amount=="") {
        setError(true);
      }
      else if (leadData.gdStatusVO.gdStatusID === "" ) {
        setError(true);
      }
      else if (leadData.gdStatusVO.gdStatusID !== "1" && leadData.gdLink=="") {
        setError(true);
      }
      if (leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.partnerVO?.partnerId === "" ) {
        setError(true);
      }
      else if (leadData.leadTypeVO.leadTypeID === "1"  &&  leadData.partnershipVO?.partnershipId === "" ) {
        setError(true);
      } 
      else {
        handleNext()
      }
       console.log("LeadDetails LeadData:",leadData)
       
    }
  return (
    // <Form name="page1" {...props}>
    <div className="row">
    <div className="col-md-12 mt-4">
                  <div className="row">
                        <div className="col-md-4">
                          <span
                            className="d-flex justify-content-end"
                            id="mandatory"
                          >
                            Lead Type
                          </span>
                        </div>
                        <div className="col-md-7">
                          {leadTypes?.map((types, i) => {
                            return (
                              <div
                                className="form-check form-check-inline"
                                key={i}
                              >
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="leadTypeVO.leadTypeID"
                                    className="form-check-input"
                                    // onChange={e => { handleChange(); leadFunction ()}}
                                    onChange={handleChangeForLeadType}
                                     checked={types.leadTypeID == leadData.leadTypeVO?.leadTypeID ? true:false}
                                    value={types.leadTypeID}
                                  />

                                  {types.leadType}
                                </label>
                              </div>
                            );
                          })}
                           {error &&  leadData.leadTypeVO?.leadTypeID == "" || leadData.leadTypeVO?.leadTypeID === null || leadData.leadTypeVO?.leadTypeID === undefined ?
                            (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Lead Type is required.
                                        </span>
                                    )
                                     : (
                                        ""
                                    )}
                        </div>
                        {
                         leadData.leadTypeVO.leadTypeID === "1"  ?(
                            <Fragment>
                                <div className="row">
                      <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "id="mandatory"
                          >
                            Partner
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                          // style={{width:"430px"}}
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="partnerVO.partnerId"
                            onChange={handleChangeForPartner}
                            value={leadData.partnerVO?.partnerId}
                          >
                            <option value="">Please Select</option>
                            {partner?.length > 0 ? (
                                <Fragment>
                                  {partner.map((partner, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partner?.partnerId}
                                      >
                                        {partner?.partnerName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.leadTypeVO.leadTypeID === "1"  && leadData.partnerVO?.partnerId === "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                         Partner is required
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "id="mandatory"
                          >
                          Channel Partner
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                          // style={{width:"430px"}}
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="partnershipVO.partnershipId"
                            onChange={handleChangeForPartnerShip}
                            value={leadData.partnershipVO?.partnershipId}
                          >
                            <option value="">Please Select</option>
                            {partnerShip?.length > 0 ? (
                                <Fragment>
                                  {partnerShip.map((partner, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partner?.partnershipId}
                                      >
                                        {partner?.partnershipName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select>
                        </div>
                        <div className="col-md-4"></div>
                            <div className="col-md-7">
                                     {error &&  leadData.leadTypeVO.leadTypeID === "1"  && leadData.partnershipVO?.partnershipId === "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Channel Partner is required
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                      </div>
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Project
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          {/* <select
                          style={{width:"430px"}}
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="projectVO.projectId"
                            onChange={handleChangeForProject}
                            value={leadData.projectVO?.projectId}
                          >
                            <option value="">Please Select</option>
                            {project?.length > 0 ? (
                                <Fragment>
                                  {project.map((project, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={project?.projectId}
                                      >
                                        {project?.projectName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                          </select> */}
                           <input
                            type="text"
                            name="projectName"
                            className="form-control"
                            placeholder="Enter Project Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleInputChange}
                            value={leadData.projectName}
                          />
                        </div>
                      </div>
                            </Fragment>
                         ):null
                      }
                        {leadTyp?.length > 0 ? (
                        <div className="row">
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end"
                              id="mandatory"
                            >
                              Type
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <select
                              id=""
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="typesVO.typeId"
                              onChange={handleChangeForType}
                              value={leadData.typesVO.typeId}
                            >
                              <option value="">Please Select</option>
                              {leadTyp?.length > 0 ? (
                                <Fragment>
                                  {leadTyp.map((Types, index) => {
                                    return (
                                      <option value={Types.typeId} key={index}>
                                        {Types.type}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                            {error &&  leadData.typesVO?.typeId == ""  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Type is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                          </div>
                          {subtypes !== null ? (
                            <Fragment>
                              <div className="col-md-4 mt-3">
                                <span className="d-flex justify-content-end  ">
                                  Sub Type
                                </span>
                              </div>
                              <div className="col-md-5 mt-2">
                                <select
                                  class="form-select form-select-md"
                                  aria-label=".form-select-md example"
                                  name="subTypesVO.subTypeID"
                                  onChange={handleChangeForSubType}
                                  value={leadData.subTypesVO?.subTypeID}
                                >
                                  <option value="">Please select</option>
                                  {subtypes?.length > 0 ? (
                                    <Fragment>
                                      {subtypes.map((subtype, index) => {
                                        return (
                                          <option
                                            value={subtype.subTypeID}
                                            key={index}
                                          >
                                            {subtype.subTypes}
                                          </option>
                                        );
                                      })}
                                    </Fragment>
                                  ) : null}
                                </select>
                                {error && leadData.subTypesVO?.subTypeID == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Sub  Type is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                              </div>
                            </Fragment>
                          ) : null}

                          {
                            leadData.leadTypeVO.leadTypeID === "2" ? <Fragment>
                              <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end"
                              id="mandatory"
                            >
                              Cover
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <input
                              type="number"
                              name="coverAmount"
                              className="form-control"
                              placeholder="Enter Cover Amount"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={handleChange}
                              value={leadData.coverAmount}
                            />
                            {error && leadData.leadTypeVO.leadTypeID === "2" && leadData.coverAmount == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Cover Amount is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                          </div>
                            </Fragment>:
                            <Fragment>
                              <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end"
                              id="mandatory"
                            >
                              Amount
                            </span>
                          </div>
                          <div className="col-md-5 mt-2">
                            <input
                              type="number"
                              name="amount"
                              className="form-control"
                              placeholder="Enter Loan Amount"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={handleChange}
                              value={leadData.amount}
                            />
                            {error && leadData.leadTypeVO.leadTypeID === "1" && leadData.amount == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                           Amount is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                          </div>
                            </Fragment>
                          }
                          

                        </div>
                      ) : null}

                        <div className="row">
                     {/* <p id="heading2">Lead Owner Details</p> */}
                        <div className="col-md-4 mt-3">
                          <span
                            className="d-flex justify-content-end  "
                          >
                            Lead Initiator
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="initiatorVO.initiatorID"
                            onChange={handleChangeForInitiator}
                            value={leadData.initiatorVO?.initiatorID ==="" ? leadData.initiatorVO.initiatorID = Initiator.userID : leadData.initiatorVO?.initiatorID}
                          >
                            <option value="">Please Select</option>
                            {user?.length > 0 ? (
                              <Fragment>
                                {user.map((user, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={user?.userId}
                                    >
                                      {user?.userName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                        </div>
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end ">
                            Branch{" "}
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          {iniBranch?.userId !== null ? ( 
                            <div>
                              <input
                              style={{background:"rgba(4,4,4,0.10)"}}
                                type="text"
                                name="branch.branch"
                                value={iniBranch?.branch?.branch}
                                className="form-control sm"
                              />
                            </div>
                           ) : null}
                        </div>

                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end  ">
                            Lead Owner
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                          {usr?.userName == null ? (
                            <Fragment>
                              <a
                                // href=""
                                style={{cursor:"pointer"}}
                                aria-hidden="true"
                                className=" button mx-3"
                                onClick={NavigateTOAssignModal}
                              >
                                <span id="assignowner">Assign Now</span>
                              </a>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {usr?.userName}
                               <a
                                // href="#react"
                                style={{cursor:"pointer"}}
                                aria-hidden="true"
                                className=" button mx-3"
                                onClick={NavigateTOAssignModal}
                              >
                                <span id="assignowner">Reassign</span>
                              </a> 
                            </Fragment>
                          )}
                          <Modal isOpen={modal}  fade={true}   centered={true}>
                            <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                              <span id="assignowner1">Assign Lead</span>
                            </ModalHeader>
                            <ModalBody>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-4 mt-3">
                                        <span className="d-flex justify-content-end  ">
                                          Lead Owner
                                        </span>
                                      </div>
                                      <div className="col-md-8 mt-2">
                                        <select
                                          class="form-select form-select-md"
                                          aria-label=".form-select-md example"
                                          name="userVO.userId"
                                          onChange={handleChangeInputForOwner}
                                          value={leadData.userVO?.userId}
                                        >
                                          <option value="">
                                            Please select Owner
                                          </option>
                                          {user?.length > 0 ? (
                                            <Fragment>
                                              {user.map(
                                                (owner, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      value={owner.userId}
                                                    >
                                                      {owner.userName}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </Fragment>
                                          ) : null}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <div className="container">
                                <div className="row ">
                                  <div className="col-md-12">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                      <Button
                                        color="secondary"
                                        id="btn-cancel"
                                        onClick={toggle}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        onClick={toggle}
                                        id="btn-cancel"
                                      >
                                        Assign Owner
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalFooter>
                          </Modal>
                        </div>

                        {/* <div className="row"> */}
                        <div className="col-md-4 mt-4">
                          <span
                            className="d-flex justify-content-end "
                            id="mandatory"
                          >
                            GD Status
                          </span>
                        </div>
                        <div className="col-md-5 mt-3">
                          <select
                            class="form-select form-select-md"
                            aria-label=".form-select-md example"
                            name="gdStatusVO.gdStatusID"
                            onChange={handleChangeForGd}
                            value={leadData.gdStatusVO?.gdStatusID}
                          >
                            <option value="">Please select</option>
                            {status?.length > 0 ? (
                              <Fragment>
                                {status.map((status, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={status?.gdStatusID}
                                    >
                                      {status?.gdStatus}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            ) : null}
                          </select>
                          {error &&  leadData.gdStatusVO?.gdStatusID == ""  ?
                            (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            GD Status is required.
                                        </span>
                                    )
                                     : (
                                        ""
                                    )}
                        </div>

                        {/* </div> */}
                        {leadData.gdStatusVO?.gdStatusID === "2" && (
                          <div className="row">
                            <div className="col-md-4 mt-3">
                              <span className="d-flex justify-content-end  ">
                                Google Form Link
                              </span>
                            </div>
                            <div className="col-md-5 mt-2">
                              <input
                                id="gdlink"
                                type="text"
                                className="form-control md"
                                placeholder="Enter Google Form Link"
                                name="gdLink"
                                onChange={handleInputChange}
                                value={leadData.gdLink}
                              />
                                  {error &&  leadData.gdStatusVO?.gdStatusID === "2" && leadData.gdLink==""  ?
                            (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            GD Link is required.
                                        </span>
                                    )
                                     : (
                                        ""
                                    )}
                            </div>
                          </div>
                        )}
                        {leadData.gdStatusVO?.gdStatusID === "3" && (
                          <div className="row">
                            <div className="col-md-4 mt-3">
                              <span className="d-flex justify-content-end  ">
                                Google Form Link
                              </span>
                            </div>
                            <div className="col-md-5 mt-2">
                              <input
                                id="gdlink"
                                type="text"
                                className="form-control md"
                                placeholder="Enter Google Form Link"
                                name="gdLink"
                                onChange={handleInputChange}
                                value={leadData.gdLink}
                              />
                               {error &&  leadData.gdStatusVO?.gdStatusID === "3" && leadData.gdLink==""  ?
                            (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            GD Link is required.
                                        </span>
                                    )
                                     : (
                                        ""
                                    )}
                            </div>
                          </div>
                        )}
                        <div className="col-md-4 mt-3">
                          <span className="d-flex justify-content-end">
                            Lead Notes
                          </span>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <textarea
                              name="leadNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={leadData.leadNotes}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className=" d-md-flex justify-content-md-end">
                            {" "}
                            Remaining Characters: {300 - char}/{characterLimit}{" "}
                          </div>
                        </div>
                      </div>
                      
                      <div className="row mt-4">
                          <div className="col-md-12 d-flex justify-content-center">
                          {/* <button
                          className="btn btn-secondary  previousButton"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          Previous
                        </button> */}
                        <button onClick={submitFormData}
                        className="btn btn-primary nextButton"
                        >
                          Next
                        </button>
                          </div>
                        </div>
                      </div>
                  </div>
    </div>
//   </Form>
  )
}
