
import React,{Fragment,useState,useEffect} from 'react';
import { Button} from "reactstrap" ;
import axios from 'axios';
import { useParams,useNavigate} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
export default function EditApplicantDetails() {
  const{LoanCaseId}= useParams();
  const [applicant, setApplicant]=useState({});
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted]=useState(false);
  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(60),( val, index) =>  year-index);
  const[getApplicantId,setApplicantId]=useState({
    type:'',
    applicantId:'',
    applicantName:'',
    primaryNumber:'',
    secondaryNumber:'',
    email:''
  })
  const [viewCase, SetViewCase]=useState({});
    useEffect (()=>{
    const dataUrl =`https://pradhanportal.com/loanApplication/master/getLoanCaseById?loanCaseId=${LoanCaseId}`;
        axios.get(dataUrl).then((response)=>{
          setApplicantData(response.data);
          console.log("response :",response.data)
          SetViewCase(response.data);
        }).catch((err)=>{
           console.log(err);
        });
    
  },[LoanCaseId]);
  console.log("ViewLoanCase",viewCase);
  useEffect (()=>{
  const dataUrl =`https://pradhanportal.com/loanApplication/customer/getAllApplicantByLoanCaseID?loanCaseID=${LoanCaseId}`;
      axios.get(dataUrl).then((response)=>{
      
        console.log("response :",response.data)
        setApplicant(response.data);
      }).catch((err)=>{
         console.log(err);
      });
  
},[LoanCaseId]);
console.log("applicant",applicant);
const handleChangeForApplicant = (e) =>{
  setApplicantId({ 
    ...getApplicantId,
  [e.target.name]: e.target.value       
}); 
}

const id =getApplicantId?.applicantId;
const[applicantData,setApplicantData]=useState({
  applicantId:id,
  applicantName:'',
  gender:'',
  dob:'',
  type:'',
  empType:{
    empTypeID:'',
  },
  residentType:{
    residentTypeID:'',
  },
  religion:{
    religionID:'',
  },
  caste:{
    casteID:'',
  },

  qualification:{
    qualificationID:'',
  },
  qualifyingYear:'',
  maritalStatus:{
    maritalID:'',
  },
  dependents:'',
  noOfChildrens:'',
  spouseName:'',
  spouseDob:'',
  
logNotes:'',
})
applicantData.applicantId =id;
useEffect (()=>{
  const dataUrl =`https://pradhanportal.com/loanApplication/customer/getApplicantById?applicantId=${id}`;
      axios.get(dataUrl).then((response)=>{
      
        console.log("response :",response.data)
        setApplicantData(response.data);
      }).catch((err)=>{
         console.log(err);
      });
  
},[id]);
console.log("applicantID",id);
console.log("applicantData",applicantData);

const[residentType,setResidentType]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllResidentType`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setResidentType(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);



const[empmentType,setempmentType]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllEmploymentType`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setempmentType(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);


const[religion,setReligion]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllReligion`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setReligion(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);


const[caste,setCaste]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllCaste`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setCaste(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);


const[maritalStatus,setMaritalStatus]=useState([]);
useEffect(()=>{
  const d1 =`https://pradhanportal.com/loanApplication/customer/getAllMaritalStatus`;
  axios.get(d1).then((response)=>{
    console.log("response :",response.data)

    setMaritalStatus(response.data);
  }).catch((err)=>{
     console.log(err);
  });
},[]);

const[qualification,setQualification]=useState([]);
useEffect(()=>{
const d1 =`https://pradhanportal.com/loanApplication/customer/getAllQualification`;
axios.get(d1).then((response)=>{
console.log("response :",response.data)

setQualification(response.data);
}).catch((err)=>{
console.log(err);
});
},[]);

const handleChange = (e) => {  
  setApplicantData({ 
      ...applicantData,
    [e.target.name]: e.target.value       
  }); 
};
const handleChangeForEmptype = (e) => {  
  setApplicantData({ 
      ...applicantData,
      empType:{
        empTypeID:e.target.value
      }     
  }); 
};

const handleChangeForResidenttype = (e) => {  
  setApplicantData({ 
      ...applicantData,
      residentType:{
        residentTypeID:e.target.value
      }     
  }); 
};

const handleChangeForEReligion = (e) => {  
  setApplicantData({ 
      ...applicantData,
      religion:{
        religionID:e.target.value
      }     
  }); 
};

const handleChangeForCaste = (e) => {  
  setApplicantData({ 
      ...applicantData,
      caste:{
        casteID:e.target.value
      }     
  }); 
};

const handleChangeForQualification = (e) => {  
  setApplicantData({ 
      ...applicantData,
      qualification:{
        qualificationID:e.target.value
      }     
  }); 
};

const handleChangeForMaritalStatus = (e) => {  
  setApplicantData({ 
      ...applicantData,
      maritalStatus:{
        maritalID:e.target.value
      }     
  }); 
};

const handleSubmit=(e)=>{
  e.preventDefault();
  axios.put('https://pradhanportal.com/loanApplication/customer/editApplicant',applicantData).then((response)=>{
      console.log(response);
      setSubmitted(true);
    }).catch((err)=>{
      console.error(err);
    });
}; 
console.log("id======", id)
const naviagteToViewLoanCase=()=>{
  navigate(`/viewLoanCase/${LoanCaseId}`);
}
  return (
    <Fragment>
      {
       isSubmitted ? navigate(`/viewLoanCase/${LoanCaseId}`) &
       toast.success('Applicant Details Updated successfully!', {
        position: toast.POSITION.TOP_RIGHT
        }) :
        <div className="container">
            <div className="row">
            <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div className="card">
                      <form onSubmit={handleSubmit}>
                        <div className="card-header bg-primary d-flex justify-content-Start  loanCaseCardHeader">
                        <span id="assignow ner1">{viewCase.customerName} - {viewCase.id}</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                          <div className="col-md-4 mt-3">
                        {/* <span className="d-flex justify-content-end address">Office Address</span> */}
                        </div>
                        <div className="col-md-8 mt-2"></div>
                        <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  "> Applicant Name</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='applicantId'
                          onChange={handleChangeForApplicant}
                          value={getApplicantId.applicantId}
                        >
                          <option value="">Please Select</option>
                          {
                            applicant?.length > 0 ? <Fragment>
                              {
                                applicant.map((apl,index)=>{
                                  return(
                                    <option value={apl?.applicantId}key={index}>{apl.applicantName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="co-md-3"></div>
                          {
                            getApplicantId?.applicantId === null || getApplicantId?.applicantId ==="undefine" ? <Fragment>
                               <div>CustomerData</div>
                            </Fragment>:<Fragment>

                            
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end"id="mandatory">Applicant Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="applicantName"
                          
                          onChange={handleChange}
                          value={applicantData?.applicantName}
                          className="form-control sm"
                          placeholder="FirstName , MiddleName ,LastName"
                        />
                      </div><div className="col-md-3"></div>
                      </Fragment>
                          }
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end" id="mandatory">Gender</span>
                        </div>
                        <div className="col-md-6 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio7"
                              onChange={handleChange}
                              checked={applicantData.gender === "Male"}
                              value="Male"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio7"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio8"
                              onChange={handleChange}
                              value="Female"
                              checked={applicantData.gender === "Female"}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio8"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio9"
                              onChange={handleChange}
                              checked={applicantData.gender === "Other"}
                              value="Other"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio9"
                            >
                              Other
                            </label>
                          </div>

                        </div><div className="col-md-3"></div>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end"id="mandatory">DOB</span>
                      </div>
                      <div  className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="dob"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          // value={applicantData.dob}
                          value={applicantData?.dob !=="" ? moment(applicantData?.dob).format('YYYY-MM-DD') : ""}
                        />
                      </div><div className="col-md-3"></div>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Employment Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='empType.empTypeID'
                          onChange={handleChangeForEmptype}
                          value={applicantData.empType?.empTypeID}
                        >
                          <option selected>Please Select</option>
                          {
                            empmentType?.length > 0 ? <Fragment>
                              {
                                empmentType.map((emp,index)=>{
                                  return(
                                    <option value={emp?.empTypeID}key={index}>{emp.empType}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Resident Type</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name="residentType.residentTypeID"
                          onChange={handleChangeForResidenttype}
                          value={applicantData.residentType?.residentTypeID}
                        >
                          <option value="">Please Select</option>
                          {
                            residentType?.length > 0 ? <Fragment>
                              {
                                residentType.map((resident,index)=>{
                                  return(
                                    <option value={resident?.residentTypeID}key={index}>{resident.residentType}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Religion</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='religion.religionID'
                          onChange={handleChangeForEReligion}
                          value={applicantData.religion?.religionID}
                        >
                          <option value="">Please Select</option>
                          {
                            religion?.length > 0 ? <Fragment>
                              {
                                religion.map((type,index)=>{
                                  return(
                                    <option value={type?.religionID}key={index}>{type.religionName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Caste</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='caste.casteID'
                          onChange={handleChangeForCaste}
                          value={applicantData.caste?.casteID}
                        >
                          <option value="">Please Select</option>
                          {
                            caste?.length > 0 ? <Fragment>
                              {
                                caste.map((type,index)=>{
                                  return(
                                    <option value={type?.casteID}key={index}>{type.casteName}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div>
                          <div className="row">
                    <p className='educationDetails'>Education Details</p>
                    <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Highest Qualification</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='qualification.qualificationID'
                          onChange={handleChangeForQualification}
                          value={applicantData.qualification?.qualificationID}
                        >
                          <option value="">Please Select</option>
                          {
                            qualification?.length > 0 ? <Fragment>
                              {
                                qualification.map((type,index)=>{
                                  return(
                                    <option value={type?.qualificationID}key={index}>{type.qualification}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end  ">Qualifying Year</span>
                          </div>
                          <div className="col-md-6 mt-2">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='qualifyingYear'
                          onChange={handleChange}
                          value={applicantData.qualifyingYear}
                        >
                          <option value="">Please Select</option>
                          
     {
       years.map((year, index) => {
         return <option key={`year${index}`} value={year}>{year}</option>
       })
     }
    
                        </select>
                          </div><div className='col-md-3'></div>
                          
                    </div>
                    <div className="row">
                    <p className='familyDetails mt-3'>Family Details</p>
                    <div className="col-md-3 mt-1">
                      <span className="d-flex justify-content-end  ">Marital Status</span>
                          </div>
                          <div className="col-md-6">
                          <select
                          class="form-select form-select-md"
                          aria-label=".form-select-md example"
                          name='maritalStatus.maritalID'
                          onChange={handleChangeForMaritalStatus}
                          value={applicantData.maritalStatus?.maritalID}
                        >
                          <option value="">Please Select</option>
                          {
                            maritalStatus?.length > 0 ? <Fragment>
                              {
                                maritalStatus.map((status,index)=>{
                                  return(
                                    <option value={status?.maritalID}key={index}>{status.maritalStatus}</option>
                                    
                                  );
                                })
                              }
                            </Fragment>:null
                          }
                        </select>
                          </div><div className='col-md-3'></div>
                          <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">No of Dependents</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="dependents"
                          className="form-control sm"
                          placeholder="Enter No of Dependents"
                          onChange={handleChange}
                          value={applicantData.dependents}
                        />
                      </div><div className='col-md-3'></div>
                     {
                      applicantData.maritalStatus?.maritalStatus === "Married"? <Fragment>
                        <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">No of Children</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="noOfChildrens"
                          className="form-control sm"
                          placeholder="Enter No of Children"
                          onChange={handleChange}
                          value={applicantData.noOfChildrens}
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Spouse Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          name="spouseName"
                          className="form-control sm"
                          placeholder="Enter Spouse Name"
                          onChange={handleChange}
                          value={applicantData.spouseName}
                        />
                      </div><div className='col-md-3'></div>
                      <div className="col-md-3 mt-3">
                        <span className="d-flex justify-content-end">Spouse DOB</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                          type="date"
                          name="spouseDob"
                          className="form-control sm"
                          placeholder="dd-mm-yy"
                          onChange={handleChange}
                          // value={applicantData.spouseDOB}
                          value={applicantData?.spouseDob !=="" ? moment(applicantData?.spouseDob).format('YYYY-MM-DD') : ""}
                        />
                      </div><div className='col-md-3'></div>
                      </Fragment>:null
                     }
                    </div>
                    <div className="row">
                   <p className='logNotes'>Log Notes</p>
                      <div className="col-md-3 mt-3">
                      <span className="d-flex justify-content-end">Log Notes</span>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group">
                              <textarea
                              name="logNotes"
                              className="form-control"
                              maxLength="300"
                              rows={3}
                              value={applicantData.logNotes}
                              onChange={handleChange}
                             />
                            </div>
                            {/* <div className=" d-md-flex justify-content-md-end"> Remaining Characters:  {300-char}/{characterLimit} </div> */}
                        </div> <div className='col-md-3'></div>
                   </div>
                          </div>
                        </div>
                        <div className="row mt-1 mb-3">
                            <div className="col-md-3"></div>
                        <div className="col-md-6 my-1">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className='previousButton' onClick={naviagteToViewLoanCase}>Cancel</Button>
                        <Button color="primary" type="submit" className='nextButton'>Update</Button>
                        </div>
                        </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
}
    </Fragment>
  )
}
