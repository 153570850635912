import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import Carousel from "react-grid-carousel";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { logInStart } from "../../store/actions";
import bankimg from "../../assets/images/u18.png";
import userIcon from "../../assets/images/user.png";
import padlock from "../../assets/images/padlock.png";
import slideimg1 from "../../assets/images/imgslide1.jpg";
import slideimg2 from "../../assets/images/imgslide2.jpg";
import pfslogo from "../../assets/images/pradhanFinservLogo_full_color.png";
import "../../assets/scss/pages/login.css";
import withRouter from "../../Components/Common/withRouter";
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(logInStart(credentials, navigate));
  };

  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className=" ">
                <div className="text-right text-white-100"></div>
                <div className="row logomargin">
                  <div className="col-md-2"></div>
                  <div className="col-md-8 mt-5">
                    <div>
                      <img
                        src={pfslogo}
                        alt="Logo"
                        height="73"
                        width="195"
                        className="logo"
                      />
                    </div>
                    <p className="mt-4 loginaccount">Login into your account</p>
                    <div className="text-center "></div>
                    <div className="form">
                      <Form onSubmit={handleSubmit} action="#">
                        {/* Username Input */}
                        <div className="mt-4">
                          <div className="input-group mb-3 input-group-lg">
                            <span
                              className="input-group-text input-group-lg"
                              id="basic-addon1"
                            >
                              <img
                                src={userIcon}
                                width="20"
                                height="15"
                                alt="user icon"
                              />
                            </span>
                            <Input
                              type="text"
                              placeholder="Username"
                              name="username"
                              value={credentials.username}
                              onChange={(e) =>
                                setCredentials({
                                  ...credentials,
                                  username: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                        </div>

                        {/* Password Input */}
                        <div className="mt-4">
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <div className="input-group mb-3 input-group-lg">
                              <span
                                className="input-group-text input-group-lg"
                                id="basic-addon1"
                              >
                                <img
                                  src={padlock}
                                  width="20"
                                  height="15"
                                  alt="padlock icon"
                                />
                              </span>
                              <Input
                                name="password"
                                className="form-control pe-5"
                                placeholder="Enter Password"
                                type={passwordVisible ? "text" : "password"}
                                value={credentials.password}
                                onChange={(e) =>
                                  setCredentials({
                                    ...credentials,
                                    password: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={togglePasswordVisibility}
                            >
                              {passwordVisible ? (
                                <RiEyeOffFill className="align-middle" size={24} />
                              ) : (
                                <RiEyeFill className="align-middle" size={24} />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-4">
                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign In
                            </Button>
                          </div>
                        </div>

                        {/* Forgot Password Link */}
                        <div className="float-start mt-4">
                          Forgot password?
                          <Link
                            to="/ForgetPWD"
                            className="passReset text-primary mx-2"
                          >
                            Reset
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className=" logincard2">
                    <div className="row">
                      <div className="col-md-12">
                        <Carousel
                          cols={1}
                          rows={1}
                          gap={10}
                          loop
                          autoplay={4000}
                          hideArrow={true}
                          style={{ height: "100%" }}
                        >
                          <Carousel.Item>
                            <div className="row logincard">
                              <img
                                width="780"
                                height="400"
                                src={bankimg}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="row">
                              <div className="card">
                                <div className="col-md-12 mt-4 text-center">
                                  <span className="hdline">News Headline</span>
                                  <p className="mt-4">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Aenean euismod bibendum
                                    laoreet. Proin gravida dolor sit amet lacus
                                    accumsan et viverra justo commodo. Proin
                                    sodales pulvinar sic tempor.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Carousel.Item>
                          <Carousel.Item>
                            <div className="row logincard">
                              <img
                                width="780"
                                height="400"
                                src={slideimg1}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="row">
                              <div className="card">
                                <div className="col-md-12 mt-4 text-center">
                                  <span className="hdline">News Headline</span>
                                  <p className="mt-4">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Aenean euismod bibendum
                                    laoreet. Proin gravida dolor sit amet lacus
                                    accumsan et viverra justo commodo. Proin
                                    sodales pulvinar sic tempor.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Carousel.Item>
                          <Carousel.Item>
                            <div className="row">
                              <img
                                width="780"
                                height="400"
                                src={slideimg2}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="row">
                              <div className="">
                                <div className="col-md-12 mt-4 text-center">
                                  <span className="hdline">News Headline</span>
                                  <p className="mt-4">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Aenean euismod bibendum
                                    laoreet. Proin gravida dolor sit amet lacus
                                    accumsan et viverra justo commodo. Proin
                                    sodales pulvinar sic tempor.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
