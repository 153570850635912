import { Handler } from 'leaflet';
import React,{Fragment, useState} from 'react';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
export default function AddLoanStage() {
  const [isSubmitted, setSubmitted]=useState(false);
  const navigate = useNavigate();
  const [stageData,setStageData]=useState({
    loanStage: '',
  });
 const handleChange=(e)=>{
  setStageData({
    ...stageData,
    [e.target.name]: e.target.value,
  })
 }

 const handleSubmit=(e)=>{
  e.preventDefault();
  axios.post('https://pradhanportal.com/loanApplication/master/addLoanStage',stageData).then((response)=>{
    console.log(response);
    setSubmitted(true);
  }).catch((err)=>{
    console.error(err);
  });
 }

 const navigateToPrevious=()=>{
  navigate('/loanStage');
 }
  return (
    <Fragment>
      {
       isSubmitted ? navigate(`/loanStage`) &
       toast.success('Loan Stage Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                      <form onSubmit={handleSubmit}>
                      <div className="card-header bg-primary d-flex justify-content-center cardHeader">
                        <span id="assignowner1">Add New Loan Stage</span>
                        </div>
                        <div className="card-body">
                          <div className="row mt-2">
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Loan Stage</span>
                      </div>
                      <div className="col-md-5 mt-2">
                        <input
                          type="text"
                          name="loanStage"
                          className="form-control sm"
                          required
                          placeholder="Enter Loan Stage"
                          value={stageData.loanStage}
                          onChange={handleChange}
                        /> 
                      </div>
                      <div className="col-md-3"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary" className="cancelButton"  onClick={navigateToPrevious}>Cancel</Button>
                        <Button color="primary"className="submitButton" type="submit" >Add Stage</Button>
                        </div>
                        </div>
                            </div>
                      </form>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}

