import React,{Fragment} from 'react';
import { Button, Modal, ModalFooter,ModalHeader, ModalBody} from "reactstrap" ;
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import {  toast } from 'react-toastify';


export default function Partnership() {
    const [isSubmitted, setSubmitted]=useState(false);
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [partner,setPartner]=useState([]);
    const navigate = useNavigate();
    const[data,setData]=useState({
      partnerVO:{
        partnerId:''
      },
      partnershipName:'',
      mobileNumber:'',
      emailId:'',
      accountHolderName:'',
      accountNumber:'',
      ifscCode:"",
      dateOfJoining:'',
      bankVO:{
        bankId:'',
      },
      bankBranchVO:{
        bankBranchId:'',
      }
    });

    const [partnerData,setPartnerData] = useState([]);
    useEffect(() => {
      const d6 = 'https://pradhanportal.com/loanApplication/master/getAllPartner';
      axios
        .get(d6)
        .then((response) => {
          console.log("Bank List response :", response.data);
  
          setPartnerData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

  
  const [banks,setBanks] = useState({});
  useEffect(() => {
    const d6 = 'https://pradhanportal.com/loanApplication/master/getAllBank';
    axios
      .get(d6)
      .then((response) => {
        console.log("Bank List response :", response.data);

        setBanks(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const bnkId= data.bankVO.bankId;
  console.log("BankID",bnkId);
  const [bankBranch, setBankBranch] = useState({});
  useEffect(() => {
    const d6 = `https://pradhanportal.com/loanApplication/master/getAllBranchByBankId?bankId=${bnkId}`;
    axios
      .get(d6)
      .then((response) => {
        console.log("response :", response.data);

        setBankBranch(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bnkId]);
  console.log("bankBranch",bankBranch);

  const handleChangeForBank =(e)=>{
    setData({
        ...data,
        bankVO: {
          bankId:e.target.value
          },
    })
  }
  const handleChangeForBankBranch =(e)=>{
    setData({
        ...data,
        bankBranchVO: {
          bankBranchId:e.target.value
          },
    })
  }

  const handleChangeForPartner=(e)=>{
    setData({
        ...data,
        partnerVO: {
          partnerId:e.target.value
          },
    })
  }
  const handleChange=(e)=>{
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
   }
   const navigateToPrevious=()=>{
    navigate('/addPartnershipManagement');
   }
   const handleSubmit=(e)=>{
    e.preventDefault();
    axios.post('https://pradhanportal.com/loanApplication/master/addPartnership',data).then((response)=>{
      console.log(response);
      setSubmitted(true);
    }).catch((err)=>{
      console.error(err);
    });
   }
  return (
    <Fragment>
         {
       isSubmitted ? navigate(`/addPartnershipManagement`) &
       toast.success('Channel Partner Added successfully!', {
        position: toast.POSITION.TOP_RIGHT
        })
       :
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                   <form onSubmit={handleSubmit}>
                   <div className="card">
                        <div className="card-header bg-primary d-flex justify-content-center  cardHeader">
                        <span id="assignowner1">Add New Channel Partner</span>
                        </div>
                        <div className="card-body">
                          <div className="row mt-2">
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Partner
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="partnerVO.partnerId"
                              required
                              onChange={handleChangeForPartner}
                              value={data.partnerVO?.partnerId ==="" ? data.partnerVO.partnerId = partner.partnerId : data.partnerVO?.partnerId}
                            >
                              <option value="">Please Select</option>
                              {partnerData?.length > 0 ? (
                                <Fragment>
                                  {partnerData.map((partner, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={partner?.partnerId}
                                      >
                                        {partner?.partnerName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                            <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Channel Partner Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="partnershipName"
                          value={data.partnershipName}
                          required
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Channel Partner Name"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>

                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Channel Partner Mo.No.</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="number"
                         name="mobileNumber"
                          value={data.mobileNumber}
                         onChange={handleChange}
                         required
                          className="form-control sm"
                          placeholder="Enter Channel Partner Number"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Channel Partner Email ID</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="email"
                         name="emailId"
                          value={data.emailId}
                          
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Channel Partner Email ID"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>
                      <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Bank
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bankVO.bankId"
                              onChange={handleChangeForBank}
                              value={data.bankVO?.bankId ==="" ? data.bankVO.bankId = banks.bankId : data.bankVO?.bankId}
                            >
                              <option value="">Please Select</option>
                              {banks?.length > 0 ? (
                                <Fragment>
                                  {banks.map((bank, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={bank?.bankId}
                                      >
                                        {bank?.bankName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                          <div className="col-md-4 mt-3">
                            <span
                              className="d-flex justify-content-end  "
                            >
                              Branch
                            </span>
                          </div>
                          <div className="col-md-6 mt-2">
                            <select
                              class="form-select form-select-md"
                              aria-label=".form-select-md example"
                              name="bankBranchVO.bankBranchId"
                              onChange={handleChangeForBankBranch}
                              value={data.bankBranchVO?.bankBranchId ==="" ? data.bankBranchVO.bankBranchId = bankBranch.bankBranchId : data.bankBranchId?.bankBranchId}
                            >
                              <option value="">Please Select</option>
                              {bankBranch?.length > 0 ? (
                                <Fragment>
                                  {bankBranch.map((branch, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={branch?.bankBranchId}
                                      >
                                        {branch?.bankBranchName}
                                      </option>
                                    );
                                  })}
                                </Fragment>
                              ) : null}
                            </select>
                          </div>
                          <div className="col-md-2 mt-2"></div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Account Holder Name</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="accountHolderName"
                          value={data.accountHolderName}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Account Holder Name"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Account Number</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="accountNumber"
                          value={data.accountNumber}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter  Account Number"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">IFSC Code</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="text"
                         name="ifscCode"
                          value={data.ifscCode}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter IFSC Code"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>
                      <div className="col-md-4 mt-3">
                        <span className="d-flex justify-content-end">Date of Activation</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <input
                         type="date"
                         name="dateOfJoining"
                          value={data.dateOfJoining}
                         onChange={handleChange}
                          className="form-control sm"
                          placeholder="Enter Date Of Activation"
                        /> 
                      </div>
                      <div className="col-md-2 mt-2"></div>
                          </div>
                        </div>
                            <div className="row mt-3 mb-3">
                        <div className="col-md-12">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button color="secondary"  className="cancelButton" onClick={navigateToPrevious}>Cancel</Button>
                        <Button color="primary" type="submit" className="submitButton">Add Channel Partner</Button>
                        </div>
                        </div>
                            </div>
                    </div>
                   </form>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
}
    </Fragment>
  )
}

