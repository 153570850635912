import React,{Fragment, useMemo}from 'react'
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Input,} from "reactstrap";
import "rc-tooltip/assets/bootstrap.css";
// import "../../assets/scss/pages/lead.css";
import "../../../assets/scss/pages/lead.css";
import axios from "axios";
import Tooltip from "rc-tooltip";
import TableContainer from '../../../Components/Common/TableContainer';
import { time } from 'echarts';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import { Pagination } from 'react-bootstrap';
import * as XLSX from "xlsx";
import excelIcon from "../../../assets/images/excel.png";
import moment from 'moment';
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap" ;
import leftChevron from "../../../assets/images/left-chevron.png";
import {List} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useFormik } from "formik";

const initialValues= {     
  loanStage:"",
}

export default function LoanStages() {
  var renderTooltip = <span>Add New Stage</span>;
  var renderTooltipexcel = <span>Export Data</span>;
  const navigateToSettings = () => {
    navigate(`/applicationSetting`);
  };
  const navigateToAddStage=()=>{
    navigate(`/addLoanStage`)
  }

  const [isSubmitted, setSubmitted]=useState(false);
  const {values,errors,handleBlur,handleChange,handleSubmit,touched}=useFormik({
    initialValues:initialValues,
    onSubmit:(values)=>{
      axios.post('https://pradhanportal.com/loanApplication/master/addLoanStage',values).then((response)=>{
        console.log(response);
        setSubmitted(true);
      }).catch((err)=>{
        console.error(err);
      });
    setSubmitted(true);
    toast.success('Loan Stage created successfully !', {
      position: toast.POSITION.TOP_RIGHT
  });
    },
   });

    const navigate = useNavigate();
  
    const[filterLeads,setFilterLeads]=useState([]);
    const [search,setSearch]=useState("");
    const [data, setData] = useState([]);
    const toggle = () => setModal(!modal);
    const [modal, setModal] = React.useState(false); 
    const [loanStages,setLoanStages]=useState([]);
    const [selected, setSelected] = useState([]);
    const [autofetch, setAutofetch] = useState([]);
    const[dataAll,setDataAll]=useState([]);
const [formData,setFormData]= useState({
  loanStage:"",raisedFor:""
})

  
   
    
    const loadLead = async (value) => {
      if (value != null) {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLoanStage`)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      } else {
        const result = await axios.get(`https://pradhanportal.com/loanApplication/master/getAllLoanStage`)
        setDataAll(result.data);
        console.log("getListOfAllCases",result.data)
      }
    };
   
    useEffect(() => {
      loadLead();
    }, []);
    const [showRecord, setshowRecord] = useState(10);

    const  handleChanegeShowRec = (e) => {
      const { name, value, type, checked } = e.currentTarget;
   
      setshowRecord(e.currentTarget.value);
  
      loadLead(value);
     }
    //for table records per page
    const [recordCount, setrecordCount] = useState(10);
   
   
    //for pagination
   
    const [pageData, setPageData] = useState([]);
   const [page, setPage] = useState(1);
   const [pageCount, setPageCount] = useState(10);
   // handle next
   const handleNext = () => {
      if (page === pageCount) return page;
      setPage(page + 1)
   }
   
   // handle previous
   const handlePrevios = () => {
      if (page === 1) return page;
      setPage(page - 1)
   }
   
   useEffect(() => {
    loadLead()
   }, [page])
   
   useEffect(() => {
      const pagedatacount = Math.ceil(dataAll.length / showRecord);
      setPageCount(pagedatacount);
   
      if (page) {
          const LIMIT = showRecord;
          const skip = LIMIT * page // 5 *2 = 10
           const dataskip = dataAll.slice(page === 1 ? 0 : skip - LIMIT, skip);
          setPageData(dataskip)
      }
   }, [search,dataAll])
   useEffect(() => {
    const filteredData = dataAll.filter((stage) =>
      stage.loanStage?.toLowerCase().includes(search.toLowerCase())
    );
    setLoanStages(filteredData);
    const totalPages = Math.ceil(filteredData.length / showRecord);
    setPageCount(totalPages);
    const startIndex = (page - 1) * showRecord;
    const endIndex = startIndex + showRecord;
    setPageData(filteredData.slice(startIndex, endIndex));
  }, [dataAll, search, showRecord, page]);

    const createdDate=(dataAll)=>{
      return moment(dataAll.createdDate)
      .format("DD-MMM-YYYY")
    }

  const columns = useMemo(
    () => [
      
      {
        Header: "Stage ID",
        accessor: "generatedId",
      },
      {
        Header: "Stage",
        accessor: "loanStage",
       
      },
      
      {
        Header: "Created Date",
        // accessor: "createdDate",
        accessor: (d) => {
          return moment(d.createdDate)
            .format("DD-MMM-YYYY")
        },
        filterable: false
      },
      {
        Header: "Number of tasks",
        accessor: "totalTask",
        filterable: false
      },
    ],
    []
  );
  return (
    <fragment>
      <Container fluid>
            <Row>
                <Col lg={12}>
                <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                    <div className='d-flex align-items-center '>
                    {/* <span className='float-start'><img src={leftChevron} width = "30" height = "25" alt='' className='mx-4'  onClick={navigateToSettings}/></span> */}
              <div className='d-inline-block'>
                <h3> Loan Stages</h3>
              </div>
              <div className='d-inline-block'>
              <Tooltip 
                   placement="right"
                   overlay={renderTooltip}
              >
              <h2><i onClick={navigateToAddStage} className="bi bi-plus-square-fill btn btn-lg"></i> </h2>  
            </Tooltip>
              </div>
            </div>
              </Col>
              <Col sm={5}></Col>
                  <Col sm={8} style={{marginTop:"0px", paddingLeft:"30px"}}>
                  <div className='' style={{float:'left'}}>
           <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
           value={showRecord}
                     className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                     <option  value="10">Show 10</option>
                     <option value="25">Show 25</option>
                     <option value="50">Show 50</option>
                     <option value="100">Show 100</option>
   
                     </select>
                     </div>
                  </Col>
                  {/* <Col sm={1}></Col> */}
                  <Col sm={1} style={{marginTop:"0px"}}>
                  <div className="d-inline-block">
                        {/* <Tooltip placement="left" overlay={renderTooltipexcel}>
                           <img src={excelIcon} width = "35" height = "30" alt='' className='mx-1'  style={{cursor:"pointer"}}/>
                        </Tooltip> */}
                      </div>
                  </Col>
                  <Col sm={3} style={{marginTop:"0px",paddingRight:"30px"}}>
                
                    <div className="search-box">
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder="Search for..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                <div className="main">
                {
                 dataAll?.length > 0 ?(
                    <>
                    <div className="container-fluid">
                 <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive" style={{overflowX:"auto",height:"600px"}}
                    id="tableData">
                      <table className="table align-items-center table-flush table-hover" id="dataTableHover">
                        <thead className="tableheader">
                        <tr>    
          <th scope="col" className="tableroww">Sr.No.</th>
       <th scope="col" className="tableroww">Stage ID</th>
         <th scope="col" className="tableroww">Stage</th>
         {/* <th scope="col" className="tableroww">Created Date</th> */}
         <th scope="col" className="tableroww">Number of Task</th>
       </tr>
     </thead>
     <tbody>
      
      {
          pageData?.map((dataAll,index)=>( 
              <tr key={index}>
              <th scope='row' className="tableroww1">{index + 1}</th>
              <td className="tableroww1 capitalizetxxxt">
              <Link id="leadid1" to={`/viewLoanStage/${dataAll.loanStageID}`}>
        {dataAll.generatedId}
           </Link>
              </td>
              <td className="tableroww1 capitalizetxxxt">{dataAll.loanStage}</td>
              {/* <td className="tableroww1 capitalizetxxxt">{createdDate(dataAll)}</td> */}
               <td className="tableroww1 capitalizetxxxt">{dataAll.totalTask}</td>
              </tr>
  
  
           ))
      } 
    </tbody>
       </table>
       {dataAll?.length > 0 && (
                      <div className="" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => handlePrevios()}
                            disabled={page === 1}
                          />
                          {Array(pageCount)
                            .fill(null)
                            .map((ele, index) => {
                              return (
                                <>
                                  <Pagination.Item
                                    active={page === index + 1 ? true : false}
                                    onClick={() => setPage(index + 1)}
                                  >
                                    {index + 1}
                                  </Pagination.Item>
                                </>
                              );
                            })}
                          <Pagination.Next
                            onClick={() => handleNext()}
                            disabled={page === pageCount}
                          />
                        </Pagination>
                      </div>
                    )}
                            </div>
                  </div>
                 </div>
                 </div>
                     </>
                   ):<>
                   <div>No Records Available</div></>
                }
                  </div>
                  </CardBody>
                {
                  isSubmitted ? navigate('/loanStage'):
                  <Modal isOpen={modal} id="loanStageModal">
                  <ModalHeader className="bg-primary d-flex justify-content-center pb-1 pt-1">
                     <span id="assignowner1">Add New Loan Stage</span>
                 </ModalHeader>
                 <ModalBody>
                    <div className="container-fluid">
                     <div className="row">
                       <div className="col-md-12">
                         <form onSubmit={handleSubmit}>
                           <div className="row">
                         <div className="col-md-4 mt-4">
                       <span className="d-flex justify-content-end  ">Loan Stage</span>
                           </div>
                           <div className="col-md-8 mt-3">
                            <input
                           type="search"
                           name="loanStage"
                            value={search}
                           onChange={(e)=>setSearch(e.target.value)}
                            onBlur={handleBlur} 
                           className="form-control sm"
                           placeholder="Enter New Stage Name"
                         /> 
                         {
                           search ? <Fragment>
                             {
                               loanStages?.map((stage,index)=>{
                                 return (
                                 <ul key={index}>{stage.loanStage}</ul>
                                     )
 
                               })
                             }
                           </Fragment>:null
                         } 
                           </div>
                         
                           <div className="col-md-12 mt-4">
                         <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                         <Button color="secondary"  id="btn-cancel" onClick={toggle}>Cancel</Button>
                         <Button color="primary" type="submit" id="btn-cancel">Add Stage</Button>
                         </div>
                         </div>
                         </div></form>
                       </div>
                     </div>
                    </div>
                 </ModalBody>
             </Modal>
                }  
                </Card>
                </Col>
            </Row>
        </Container>
    </fragment>
  )
}
